import { ChangeEventHandler } from 'react';
import { CardBody, RadioButton, Text } from '@components';
import { useNumberFunctions, useReplace, useTranslator } from '@hooks';
import { PaymentMethodTypeEnum } from '@models';
import { GetTranslatedEnumName } from '@utils';
import CheckIcon from '~/assets/svg/controls/check.svg';
import PayPalIcon from '~/assets/svg/icons/icon__paypal.svg';
import { usePaymentMethods } from '~/providers/checkoutProvider';
import { CardStyled, RadioButtonGroupForPaymentOptionStyled } from './styled';

type PaymentOptionsProps = {
    onChange: ChangeEventHandler<HTMLInputElement>;
    selectedValue: PaymentMethodTypeEnum;
    showDiscounts?: boolean;
};
export const PaymentOptions = ({ onChange, selectedValue, showDiscounts }: PaymentOptionsProps) => {
    const {
        checkoutPage: { paymentMethod },
        enums
    } = useTranslator();
    const { paymentMethods } = usePaymentMethods();
    const { formatPercentage } = useNumberFunctions();
    const replace = useReplace();

    return (
        <CardStyled>
            <CardBody>
                <RadioButtonGroupForPaymentOptionStyled name='paymentMethod' defaultValue={selectedValue}>
                    {paymentMethods
                        ?.filter(
                            (item) =>
                                item.type !== PaymentMethodTypeEnum.StripeBankSlip &&
                                item.type !== PaymentMethodTypeEnum.StripeCreditCard
                        )
                        ?.map(({ id, type: paymentMethodType, name, discountPercentage }) => (
                            <RadioButton
                                key={id}
                                value={Number(paymentMethodType)}
                                onChange={onChange}
                                checked={selectedValue === Number(paymentMethodType)}
                                testId={`button__payment-method-${name}`}>
                                <CheckIcon
                                    className='check-radio__icon'
                                    data-selected={selectedValue === Number(paymentMethodType)}
                                />
                                <div>
                                    {paymentMethodType !== PaymentMethodTypeEnum.Gateway ? (
                                        <Text size='xs'>
                                            {GetTranslatedEnumName(
                                                PaymentMethodTypeEnum[paymentMethodType],
                                                enums.paymentMethodTypeEnum
                                            )}
                                        </Text>
                                    ) : (
                                        <PayPalIcon />
                                    )}
                                    {showDiscounts && discountPercentage && (
                                        <Text as='span'>
                                            {replace(
                                                paymentMethod.discountPercentage,
                                                formatPercentage(discountPercentage)
                                            )}
                                        </Text>
                                    )}
                                </div>
                            </RadioButton>
                        ))}
                </RadioButtonGroupForPaymentOptionStyled>
            </CardBody>
        </CardStyled>
    );
};
