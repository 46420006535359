import { InputFormGroup, SelectFormGroup, SelectData } from '@components';
import { useTranslator } from '@hooks';
import { NavigationTriggerRuleLogicalOperator } from '@models';
import { RuleDefaultProps } from '../';
import { RemoveRuleButton } from './removeRuleButton';
import { InputStyled, LogicalOperatorStyled } from './styles';

type LogicalOperatorDropdownProps = Pick<
    RuleDefaultProps,
    'animation' | 'onRemove' | 'rule' | 'ruleElementAnimationIndex'
> & {
    isVisible?: boolean;
    showRemoveButton?: boolean;
    debugValue?: string;
};

export const LogicalOperatorDropdown = ({
    animation,
    rule,
    ruleElementAnimationIndex,
    showRemoveButton = true,
    debugValue = null,
    isVisible = true,
    onRemove
}: LogicalOperatorDropdownProps) => {
    const {
        enums: { navigationTriggerRuleLogicalOperator }
    } = useTranslator();

    if (!isVisible) {
        return <></>;
    }

    const dropdownOptions: Array<SelectData> = [
        {
            id: NavigationTriggerRuleLogicalOperator.And,
            label: String(navigationTriggerRuleLogicalOperator.and)
        },
        {
            id: NavigationTriggerRuleLogicalOperator.Or,
            label: String(navigationTriggerRuleLogicalOperator.or)
        }
    ];

    return (
        <LogicalOperatorStyled
            className='logical-operator'
            animate={animation}
            custom={ruleElementAnimationIndex}
            debugValue={debugValue}>
            <InputFormGroup name='id' type='text' value={rule.id} isInvisible />
            <InputFormGroup name='parentId' type='text' value={rule.parentId} isInvisible />

            <InputStyled>
                <SelectFormGroup
                    name='logicalOperator'
                    defaultValue={rule.logicalOperator ?? NavigationTriggerRuleLogicalOperator.And}
                    options={dropdownOptions}
                />
            </InputStyled>
            <RemoveRuleButton
                isVisible={showRemoveButton}
                onRemove={onRemove}
                rule={rule}
                ruleElementAnimationIndex={ruleElementAnimationIndex}
            />
        </LogicalOperatorStyled>
    );
};
