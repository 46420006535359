import first from 'lodash/first';
import _sumBy from 'lodash/sumBy';
import {
    CouponAttemptResult,
    BillingPeriod,
    Coupon,
    CouponTypeEnum,
    CustomerPlanType,
    BillingPeriodEnum,
    SendingTechnology,
    SendingTechnologyTypeEnum,
    Subscription,
    SubscriptionTool,
    SubTool,
    Tool,
    ToolTypeEnum,
    DetailedBalanceConsumption,
    SubToolIdEnum,
    Plan
} from '@models';
import {
    SubscriptionPlanFormData,
    SubscriptionPaymentFormData,
    SubscriptionFormData,
    SubscriptionDataFormData
} from '@providers';
import {
    addDays,
    BANK_SLIP_COMPENSATION_OFFSET,
    DateFormatEnum,
    formatDate,
    ONE_HUNDRED,
    convertPhoneToString,
    divideOrFallback
} from '@utils';

export type ToolDiscountData = {
    toolId: number;
    name: string;
    discountAmount?: number;
    discountPercentage?: number;
    totalCredits?: number;
};

export type SelectedToolsWithAmountData = {
    tool: { id?: number; type: ToolTypeEnum; name?: string };
    amount: number;
    currentAmount?: number;
};

export const GetBillingPeriodById = (billingPeriods: Array<BillingPeriod>, id: number) => {
    if (!billingPeriods) {
        return null;
    }

    return billingPeriods?.find((period) => period.id === id);
};

export const GetSelectedSendingTechnologies = (
    sendingTechnologies: Array<SendingTechnology>,
    selectedSendingTechnologies: Array<number>
) => {
    return (selectedSendingTechnologies || [])?.map((currentTechnolog) => {
        const technolog = sendingTechnologies?.find((technolog) => technolog.id === currentTechnolog);
        return technolog;
    });
};

export const getValueSendingTechnologiesTotal = (
    sendingTechnologies: Array<SendingTechnology>,
    selectedSendingTechnologies: Array<number>,
    monthlyValue: number
) => {
    const total = sendingTechnologies.reduce((total, technology) => {
        if (selectedSendingTechnologies?.some((selectedTechnologyId) => selectedTechnologyId === technology.id)) {
            return total + GetSendingPriceByPeriodicity(technology, monthlyValue);
        }
        return total;
    }, 0);

    return total;
};

export const CalculateSendingTechnologyValue = (
    tool: Tool,
    creditVolume: number,
    emailMarketingAmount: number,
    sendingTechnologies: Array<number>,
    monthlyValue: number
) => {
    if (tool.type === ToolTypeEnum.BulkSending && emailMarketingAmount) {
        return tool.sendingTechnologies
            ?.filter((technology) => technology.creditAmount === emailMarketingAmount)
            ?.reduce((total, technology) => total + GetSendingPriceByPeriodicity(technology, monthlyValue), 0);
    }

    if (tool.type === ToolTypeEnum.AutomationTool && creditVolume && sendingTechnologies?.length > 0) {
        return tool.sendingTechnologies
            ?.filter((technology) =>
                sendingTechnologies?.some((currentTechnology) => currentTechnology === technology.id)
            )
            ?.reduce((total, technology) => total + GetSendingPriceByPeriodicity(technology, monthlyValue), 0);
    }

    return 0;
};

export const GetSelectedAndCurrentToolsWithAmount = (
    tools: Array<Tool>,
    subscriptionPlan: SubscriptionPlanFormData,
    monthlyValue: number,
    currentSubscriptionFormData?: SubscriptionPlanFormData,
    currentMonthlyValue?: number
): Array<SelectedToolsWithAmountData> | null => {
    if (!tools || !subscriptionPlan) {
        return null;
    }

    return tools?.map((tool) => {
        const { emailMarketingAmount, creditVolume, sendingTechnologies } = subscriptionPlan;
        let currentAmount = 0;

        const amount = CalculateSendingTechnologyValue(
            tool,
            creditVolume,
            emailMarketingAmount,
            sendingTechnologies,
            monthlyValue
        );

        if (currentSubscriptionFormData) {
            const {
                emailMarketingAmount: currentEmailMarketingAmount,
                creditVolume: currentCreditVolume,
                sendingTechnologies: currentSendingTechnologies
            } = currentSubscriptionFormData || {};

            currentAmount = CalculateSendingTechnologyValue(
                tool,
                currentCreditVolume,
                currentEmailMarketingAmount,
                currentSendingTechnologies,
                currentMonthlyValue
            );
        }

        return { tool, amount, currentAmount };
    });
};

export const GetDiscountCouponByToolType = (toolType: ToolTypeEnum, discountCoupon: Coupon) => {
    if (toolType === ToolTypeEnum.AutomationTool) {
        return {
            couponTotalCredits: discountCoupon.automationToolTotal,
            couponPrice: discountCoupon.automationToolPrice,
            couponPercentage: discountCoupon.totalAutomationToolPercentage
        };
    }

    return {
        couponTotalCredits: discountCoupon.bulkSendingTotal,
        couponPrice: discountCoupon.bulkSendingPrice,
        couponPercentage: discountCoupon.totalBulkSendingPercentage
    };
};

export const GetToolDiscountWithCoupon = (
    selectedTool: SelectedToolsWithAmountData,
    couponAttemptResult: CouponAttemptResult
) => {
    const coupon = couponAttemptResult?.coupon;

    const tool = selectedTool.tool;
    if (!coupon || !couponAttemptResult.isCouponApplied) {
        return {
            toolId: tool.id,
            name: tool.name,
            discountAmount: null,
            totalCredits: null
        } as ToolDiscountData;
    }

    const { couponPrice, couponPercentage, couponTotalCredits } = GetDiscountCouponByToolType(tool.type, coupon);
    let discountAmount = 0;
    let totalCredits = null;

    if (couponPrice || couponPercentage || couponTotalCredits) {
        switch (coupon.type) {
            case CouponTypeEnum.DiscountPercentage:
                if (couponPercentage) {
                    if (couponPercentage > 1) {
                        discountAmount = selectedTool.amount * (couponPercentage / ONE_HUNDRED);
                    } else {
                        discountAmount = selectedTool.amount * couponPercentage;
                    }
                }
                break;

            case CouponTypeEnum.ClosedPlan:
                if (couponPrice) {
                    discountAmount = selectedTool.amount - couponPrice;
                    totalCredits = couponTotalCredits;
                }
                break;

            case CouponTypeEnum.ExtraCreditsForFixedValue:
            case CouponTypeEnum.ExtraCreditsForAccumulatedValue:
                discountAmount = couponPrice * -1;
                totalCredits = couponTotalCredits;
                break;

            default:
                discountAmount = null;
                break;
        }
    }

    return {
        toolId: tool.id,
        name: tool.name,
        discountAmount,
        discountPercentage: coupon.type === CouponTypeEnum.DiscountPercentage ? couponPercentage : null,
        totalCredits
    } as ToolDiscountData;
};

export const GetAllToolDiscountWithCoupon = (
    selectedTools: Array<SelectedToolsWithAmountData>,
    couponAttemptResult: CouponAttemptResult
) => {
    return selectedTools?.map((selectedTool) => {
        return GetToolDiscountWithCoupon(selectedTool, couponAttemptResult);
    });
};

export const GetSendingPriceByPeriodicity = (
    sendingTechnology: SendingTechnology,
    periodicityInMonths: BillingPeriodEnum | number
) => {
    let price = 0;

    switch (periodicityInMonths) {
        case BillingPeriodEnum.Month:
            price = sendingTechnology.monthlyValue;
            break;
        case BillingPeriodEnum.Quarter:
            price = sendingTechnology.quarterlyValue;
            break;
        case BillingPeriodEnum.Semester:
            price = sendingTechnology.semesterValue;
            break;
        case BillingPeriodEnum.Year:
            price = sendingTechnology.annualValue;
            break;
        default:
            price = sendingTechnology.monthlyValue * (periodicityInMonths ?? 1);
            break;
    }

    return price;
};

export const SetSendingPriceByPeriodicity = (
    sendingTechnology: SendingTechnology,
    periodicityInMonths: BillingPeriodEnum | number,
    newPrice: number
) => {
    switch (periodicityInMonths) {
        case BillingPeriodEnum.Month:
            sendingTechnology.monthlyValue = newPrice;
            break;
        case BillingPeriodEnum.Quarter:
            sendingTechnology.quarterlyValue = newPrice;
            break;
        case BillingPeriodEnum.Semester:
            sendingTechnology.semesterValue = newPrice;
            break;
        case BillingPeriodEnum.Year:
            sendingTechnology.annualValue = newPrice;
            break;
        default:
            sendingTechnology.monthlyValue = newPrice;
            break;
    }

    return sendingTechnology;
};

export const CalculateTotalSendingTechnologies = (
    creditVolume: number,
    sendingTechnologies: Array<SendingTechnology>,
    currentSendingTechnologies: Array<number>,
    periodicityInMonths: number
) => {
    let totalSendingTechnologies = 0;

    if (creditVolume && sendingTechnologies.length > 0) {
        totalSendingTechnologies = sendingTechnologies.reduce((total, technology) => {
            if (currentSendingTechnologies.some((currentTechnology) => currentTechnology === technology.id)) {
                return total + GetSendingPriceByPeriodicity(technology, periodicityInMonths);
            }

            return total;
        }, 0);
    }

    return totalSendingTechnologies;
};

export const GetToolByType = (tools: Array<Tool>, toolType: ToolTypeEnum) => {
    if (!tools) {
        return null;
    }
    return tools.find((tool) => tool.type === toolType);
};

export const GetSubToolByType = (subTools: Array<SubTool>, toolType: ToolTypeEnum) => {
    if (!subTools) {
        return null;
    }
    return subTools.filter((subTool) => subTool.tool.type === toolType);
};

export const GetSendingTechnologiesByCreditAmount = (
    sendingTechnologies: Array<SendingTechnology>,
    currentCreditVolume: number
) => {
    if (!currentCreditVolume || sendingTechnologies.length === 0) {
        return null;
    }
    return sendingTechnologies?.filter(
        (technology) => technology.creditAmount === currentCreditVolume && !technology.isCustomValue
    );
};

export const GetSendingTechnologyByName = (type: number, sendingTechnologyDescriptions: unknown): string => {
    const technologyName = SendingTechnologyTypeEnum[type]?.toLocaleLowerCase();
    return sendingTechnologyDescriptions[technologyName];
};

export const GetSubscriptionToolsByType = (
    subscriptionTool: Array<SubscriptionTool>,
    customerPlanType: CustomerPlanType
) => {
    if (!subscriptionTool) {
        return null;
    }
    return subscriptionTool.find((tool) => tool.type === customerPlanType);
};

export const CreateSubscriptionPlanFormData = (subscription: Subscription): SubscriptionPlanFormData => {
    if (subscription) {
        const { billingPeriod, subscriptionTools = [] } = subscription;

        const automationTool = GetSubscriptionToolsByType(subscriptionTools, CustomerPlanType.AutomationTool);
        const bulkSending = GetSubscriptionToolsByType(subscriptionTools, CustomerPlanType.BulkSendingTool);

        const sendingTechnologies = automationTool?.sendingTechnologies ?? [];
        const creditVolume = first(sendingTechnologies)?.sendingTechnology?.creditAmount ?? null;
        const emailMarketingAmount = first(bulkSending?.sendingTechnologies)?.sendingTechnology?.creditAmount ?? null;

        return {
            sendingTechnologies: sendingTechnologies?.map((technology) => technology.sendingTechnology.id),
            creditVolume,
            emailMarketingAmount,
            billingPeriodId: billingPeriod?.id || null
        };
    }

    return null;
};

export const CreateSubscriptionDataFormData = (subscription: Subscription): SubscriptionDataFormData => {
    if (subscription) {
        const { customer } = subscription;
        const { billingResponsible, address } = customer;

        return {
            billingResponsible: {
                name: billingResponsible.name ?? null,
                email: billingResponsible.email,
                telephone: convertPhoneToString(billingResponsible.phone),
                cellPhoneForWhatsApp: convertPhoneToString(billingResponsible.cellPhone)
            },
            billingData: {
                corporateName: customer.name,
                cpfCnpj: customer.documentNumber,
                address: {
                    zipCode: address.zipCode,
                    street: address.street,
                    number: address.number,
                    complement: address.complement ?? null,
                    neighborhood: address.neighborhood ?? null,
                    city: address.city,
                    stateCode: address.state?.code,
                    countryCode: address.country?.code
                }
            }
        } as SubscriptionDataFormData;
    }

    return null;
};

export const CreateSubscriptionPaymentFormData = (subscription: Subscription): SubscriptionPaymentFormData => {
    if (subscription) {
        const {
            coupons,
            paymentMethod,
            card,
            nextInvoiceDate = addDays(new Date(), BANK_SLIP_COMPENSATION_OFFSET)
        } = subscription;
        const {
            cardholderName = null,
            number = null,
            expirationMonth = null,
            expirationYear = null,
            cvv = '',
            brand = ''
        } = card || {};

        const firstDueDate = formatDate(new Date(nextInvoiceDate), DateFormatEnum.OnlyDateUSFormat);

        return {
            discountCoupon: coupons?.length > 0 ? coupons[0].couponCode : '',
            payPal: {
                nonce: null
            },
            creditCard: {
                cardholderName,
                cvv,
                brand,
                expirationMonth,
                expirationYear,
                number
            },
            hasAcceptedTheTerms: false,
            paymentMethod: paymentMethod?.type || null,
            bankSlip: {
                firstDueDate
            }
        };
    }

    return null;
};

export const CreateSubscriptionFormData = (subscription: Subscription): SubscriptionFormData => {
    const subscriptionPlan = CreateSubscriptionPlanFormData(subscription);
    const subscriptionPayment = CreateSubscriptionPaymentFormData(subscription);
    const subscriptionData = CreateSubscriptionDataFormData(subscription);

    return {
        subscriptionId: subscription?.id ?? null,
        subscriptionPlan,
        subscriptionPayment,
        subscriptionData
    };
};

export const MakeAutomationToolSummary = (
    totalCredits: number,
    totalCreditsUsed: number,
    detailedBalanceConsumption: Array<DetailedBalanceConsumption> = [],
    defaultAutomatonToolSummary: Array<{ id: SubToolIdEnum }> = []
) => {
    return defaultAutomatonToolSummary.map((automationTool) => {
        const currentAutomation = detailedBalanceConsumption.find((detail) => detail.id === automationTool.id);

        if (currentAutomation) {
            return {
                ...automationTool,
                totalCreditsUsed: currentAutomation.usedCredits,
                percentage: divideOrFallback(currentAutomation.usedCredits, totalCredits)
            };
        }

        return automationTool;
    });
};

export const MakeBulkSendingToolSummary = (
    totalCredits: number,
    totalCreditsUsed: number,
    defaultColor: string,
    noDescription: string,
    detailedBalanceConsumption: Array<DetailedBalanceConsumption> = []
) => {
    return [
        {
            id: 0,
            totalCreditsUsed: totalCreditsUsed,
            color: defaultColor,
            percentage: divideOrFallback(totalCreditsUsed, totalCredits),
            text: ''
        },
        ...detailedBalanceConsumption.map((detail) => {
            return {
                id: detail.id,
                text: detail.description || noDescription,
                color: null,
                totalCreditsUsed: detail.usedCredits,
                percentage: divideOrFallback(detail.usedCredits, totalCreditsUsed)
            };
        })
    ];
};

export const CalculateSubscriptionPlanDiscounts = (plan: Plan) => {
    if (plan.billingPeriod === BillingPeriodEnum.Year) {
        const planValue = plan.value;
        const additionalSendingTechnologiesValue = _sumBy(plan.additionalSendingTechnology, 'value');

        const numberOfInstallments = 4;
        const months = 12;

        const total = planValue + additionalSendingTechnologiesValue;
        const totalWithDiscount = total - total * (plan.discountPercentage ?? 0);
        const installmentAmount = totalWithDiscount / numberOfInstallments;
        const savings = Math.round((total - totalWithDiscount) * months);

        return {
            totalWithDiscount,
            numberOfInstallments,
            installmentAmount,
            savings
        };
    }

    return {
        totalWithDiscount: plan.value,
        numberOfInstallments: 0,
        installmentAmount: 0,
        savings: 0
    };
};
