import { ReactNode } from 'react';
import HTMLReactParser from 'html-react-parser';
import Head from 'next/head';
import { useTranslator } from '@hooks';
import LogoEnviou from '~/assets/svg/logo/logo__enviou-slogan.svg';
import { Footer, Text } from '~/components/elements';
import { HeaderContentStyled, LayoutContentStyled, LayoutStyled } from './styles';

type PlanLayoutProps = {
    children: ReactNode;
};

export const PlanLayout = ({ children }: PlanLayoutProps) => {
    const {
        pages: { subscriptionPlans }
    } = useTranslator();
    return (
        <LayoutStyled>
            <Head>
                <title>{String(subscriptionPlans.title)}</title>
            </Head>

            <HeaderContentStyled>
                <div className='logotipo'>
                    <LogoEnviou title='Logo Enviou' height='4.8rem' className='logo__enviou' />
                </div>
                <div className='banner'>
                    <Text size='4.1rem'>{HTMLReactParser(String(subscriptionPlans.description))}</Text>
                </div>
            </HeaderContentStyled>
            <LayoutContentStyled>{children}</LayoutContentStyled>
            <Footer contentSize='medium' />
        </LayoutStyled>
    );
};
