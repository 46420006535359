import { useMemo, useState } from 'react';
import _sumBy from 'lodash/sumBy';
import { Button, Heading, InputFormGroup, SelectFormGroup, Text, getSendingTechnologyIcons } from '@components';
import { useNumberFunctions } from '@hooks';
import { BillingPeriodEnum, Plan, PlanSendingTechnology, SendingTechnologyTypeEnum } from '@models';
import { Form } from '@unform/web';
import axiosClient from '~/services/apiService';
import { usePlan } from './container';
import {
    BadgeStyled,
    CustomSendingTechnologyContentStyled,
    PlanCardHeaderStyled,
    PlanCardPriceStyled,
    PlanCardStyled,
    SendingTechnologyContentStyled
} from './styles';

type PlanCardProps = {
    plan: Plan;
};

export const PlanCard = ({ plan }: PlanCardProps) => {
    const [sms, , push, email, whatsapp] = getSendingTechnologyIcons();
    const [isLoading, setIsLoading] = useState(false);

    const { formatCurrency, formatNumber } = useNumberFunctions();
    const { periodType, changeSendingTechnologyOption, additionalSendingTechnologies } = usePlan();

    const isAnnual = periodType === BillingPeriodEnum.Year;

    const sendingTechnologies = useMemo(() => {
        const whatsApp = additionalSendingTechnologies.filter(
            (item) => item.sendingTechnology === SendingTechnologyTypeEnum.WhatsApp
        );
        const sms = additionalSendingTechnologies.filter(
            (item) => item.sendingTechnology === SendingTechnologyTypeEnum.SMS
        );
        const whatsAppOptions = [
            { id: null, label: 'Selecione' },
            ...whatsApp.map((item) => {
                return {
                    id: item.creditAmount,
                    label: `${formatNumber(item.creditAmount)} por ${formatCurrency(item.value)}`
                };
            })
        ];
        const smsOptions = [
            { id: null, label: 'Selecione' },
            ...sms.map((item) => {
                return {
                    id: item.creditAmount,
                    label: `${formatNumber(item.creditAmount)} por ${formatCurrency(item.value)}`
                };
            })
        ];

        return {
            whatsApp,
            whatsAppOptions,
            smsOptions,
            sms
        };
    }, [formatCurrency, formatNumber, additionalSendingTechnologies]);

    const handleSubmit = async (formData) => {
        try {
            setIsLoading(true);
            const response = await axiosClient.post<{ checkoutUrl: string }>('/subscription/checkout', {
                subscriptionPlanId: formData.planId,
                whatsappCreditAmount: formData.whatsapp,
                smsCreditAmount: formData.sms
            });

            if (response.data && response.data.checkoutUrl) {
                window.location.assign(response.data.checkoutUrl);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <PlanCardStyled>
            {plan.bestSeller && (
                <BadgeStyled>
                    <Text color='white' as='strong'>
                        MAIS VENDIDO!
                    </Text>
                </BadgeStyled>
            )}
            <PlanCardHeaderStyled>
                <div>
                    <Heading as='h3' fontWeight={plan.bestSeller ? 'semiBold' : 'regular'}>
                        {plan.name}
                    </Heading>
                </div>
                <Text>Até {formatNumber(plan.monthlyVisits)} visitas no seu e-commerce</Text>
            </PlanCardHeaderStyled>

            <PlanCardPriceStyled>
                {isAnnual ? (
                    <>
                        <Text className='oldValue' as='strong' color='philippineSilver' size='2xl' fontWeight='medium'>
                            De R$ {formatNumber(plan.value + _sumBy(plan.additionalSendingTechnology, 'value'))}
                        </Text>

                        <Text size='6xl' as='strong'>
                            por {formatCurrency(plan.valueWithDiscount)}
                        </Text>
                    </>
                ) : (
                    <div className='value'>
                        <Text size='6xl' as='strong'>
                            R$ {formatNumber(plan.value + _sumBy(plan.additionalSendingTechnology, 'value'))}
                        </Text>
                        <Text as='span' size='xxs'>
                            por mês
                        </Text>
                    </div>
                )}
                {isAnnual && (
                    <>
                        <Text as='span' size='xxs'>
                            por mês
                        </Text>

                        <Text as='span' size='lg'>
                            1 ano de compromisso
                        </Text>

                        <div className='savings'>
                            <Text size='sm' color='white' as='strong'>
                                Economize até R$ {formatNumber(plan.savings)} no ano
                            </Text>
                        </div>
                    </>
                )}
            </PlanCardPriceStyled>

            <SendingTechnologyContentStyled>
                <Text size='sm'>{formatNumber(plan.emailMarketingSends)} envios de E-mail Marketing /mês</Text>
                <Text size='sm'>
                    {formatNumber(plan.marketingAutomationSends)} envios de Automação de Marketing /mês
                </Text>

                <div>
                    <Text size='md' color='deepCerise' as='strong'>
                        Canais de Envio
                    </Text>
                    <div>
                        <div>
                            {email.icon}
                            <Text size='xxs' color='deepCerise'>
                                E-mail
                            </Text>
                        </div>
                        <div>
                            {push.icon}
                            <Text size='xxs' color='deepCerise'>
                                Push
                            </Text>
                        </div>
                    </div>
                </div>
            </SendingTechnologyContentStyled>
            <Form onSubmit={handleSubmit}>
                <InputFormGroup type='text' containerClassName='hidden' name='planId' value={plan.id} />
                <CustomSendingTechnologyContentStyled>
                    <Heading as='h3' size='md'>
                        Canais de Envio Adicionais
                    </Heading>

                    <div>
                        <div>
                            {whatsapp.icon}
                            <Text size='xxs'>WhatsApp*</Text>
                        </div>
                        <div>
                            {sms.icon}
                            <Text size='xxs'>SMS</Text>
                        </div>

                        <SelectFormGroup
                            name='whatsapp'
                            contentOffset={48}
                            flexGrid={{ desktop: 6, mobileAndTablet: 12 }}
                            placeholder={'Selecione'}
                            options={sendingTechnologies.whatsAppOptions}
                            onOptionChange={(option) =>
                                changeSendingTechnologyOption(
                                    plan.id,
                                    sendingTechnologies.whatsApp.find(
                                        (item) => item.creditAmount === (option.id as number)
                                    ) ??
                                        ({
                                            sendingTechnology: SendingTechnologyTypeEnum.WhatsApp
                                        } as PlanSendingTechnology)
                                )
                            }
                        />

                        <SelectFormGroup
                            name='sms'
                            contentOffset={48}
                            flexGrid={{ desktop: 6, mobileAndTablet: 12 }}
                            placeholder={'Selecione'}
                            options={sendingTechnologies.smsOptions}
                            onOptionChange={(option) =>
                                changeSendingTechnologyOption(
                                    plan.id,
                                    sendingTechnologies.sms.find(
                                        (item) => item.creditAmount === (option.id as number)
                                    ) ??
                                        ({
                                            sendingTechnology: SendingTechnologyTypeEnum.SMS
                                        } as PlanSendingTechnology)
                                )
                            }
                        />
                    </div>
                    <Button color='purple' type='submit' inLoading={isLoading}>
                        Selecionar
                    </Button>
                    <ul>
                        <Text as='li' size='xxs'>
                            Atenção: a Meta impõe custos adicionais próprios pelo envio de comunicação em sua plataforma
                        </Text>
                    </ul>
                </CustomSendingTechnologyContentStyled>
            </Form>
        </PlanCardStyled>
    );
};
