import {
    BillingPeriod,
    PaymentMethod,
    Customer,
    Card,
    Coupon,
    SubscriptionTool,
    SendingTechnologyTypeEnum,
    BillingPeriodEnum
} from '@models';

export enum SubscriptionStatusEnum {
    AwaitingPayment = 0,
    Active = 1,
    Canceled = 2,
    CanceledByDownGrade = 3,
    CanceledByUpGrade = 4,
    CanceledByNonpayment = 5,
    ManualCancellationByCustomer = 6,
    ErrorProcessingRecurrence = 7,
    CanceledFoUpdate = 8,
    BillingByThirdParty = 9,
    CancellationRequested = 10
}

export enum SubscriptionTypeEnum {
    None = 0,
    RecurringSubscription = 1,
    ThemePurchase = 2,
    ThirdPartyPurchase = 3,
    Loose = 5
}

/* eslint-disable camelcase */
export enum SubscriptionVersion {
    v2_0 = 0,
    v3_0 = 1,
    v3_5 = 2
}
/* eslint-enable camelcase */

export type Subscription = {
    id?: number;
    createdDate: Date;
    type: SubscriptionTypeEnum;
    status: SubscriptionStatusEnum;
    billingPeriod: BillingPeriod;
    paymentMethod?: PaymentMethod;
    reasonForCancellation: string;
    cancellationDate?: Date;
    nextInvoiceDate: Date;
    lastCheckDate?: Date;
    customer: Customer;
    card?: Card;
    coupons: Array<Coupon>;
    subscriptionTools: Array<SubscriptionTool>;
    gatewayId: string;
    version: SubscriptionVersion;
};

export type PlanType = 'GrowthAccelerator' | 'StrategicExpansion' | 'Master';

export type PlanSendingTechnology = {
    sendingTechnology: SendingTechnologyTypeEnum.WhatsApp | SendingTechnologyTypeEnum.SMS;
    name: string;
    creditAmount: number;
    value: number;
};

export type Plan = {
    id: number;
    billingPeriod: BillingPeriodEnum;
    planType: PlanType;
    bestSeller?: boolean;
    name: string;
    monthlyVisits: number;
    value: number;
    discountPercentage?: number;
    valueWithDiscount?: number;
    emailMarketingSends: number;
    marketingAutomationSends: number;
    numberOfInstallments?: number;
    installmentAmount?: number;
    savings?: number;
    additionalSendingTechnology?: PlanSendingTechnology[];
};
