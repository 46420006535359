import { PlanContainer } from './container';
import { ContractPeriodOptions } from './contractPeriodOptions';
import { CustomizePlanQuestion, StartUpPlanCard } from './customizePlan';
import { PlanFaq } from './faq';
import { FaqButton } from './faqButton';
import { PlanCard } from './planCard';
import { PlanDetailsTable, PlanDetailsTableHeader } from './planDetailsTable';
import { CustomizePlanContainerStyled, PlanDetailContentStyled, PlansContainerStyled } from './styles';

export const Plans = {
    Container: PlanContainer,
    Card: PlanCard,
    CardContainer: PlansContainerStyled,
    FaqButton,
    DetailsTableContainer: PlanDetailContentStyled,
    DetailsTable: PlanDetailsTable,
    DetailsTableHeader: PlanDetailsTableHeader,
    ContractPeriodOptions,
    CustomizePlanContainer: CustomizePlanContainerStyled,
    CustomizePlanQuestion,
    StartUpPlanCard,
    Faq: PlanFaq
};
