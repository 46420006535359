import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { BillingPeriodEnum, Plan, PlanSendingTechnology } from '@models';
import { CalculateSubscriptionPlanDiscounts } from '~/services/subscriptionService';
import { PlanStyled } from './styles';

export type PlanContextValue = {
    periodType: BillingPeriodEnum;
    plans: Plan[];
    additionalSendingTechnologies: PlanSendingTechnology[];
    changePeriodType: (periodType: BillingPeriodEnum) => void;
    changeSendingTechnologyOption: (planId: number, additionalSendingTechnology?: PlanSendingTechnology) => void;
};

type PlanContainerProps = {
    plans: Plan[];
    additionalSendingTechnologies: PlanSendingTechnology[];
    children: ReactNode;
};

export const PlanContext = createContext<PlanContextValue>({} as PlanContextValue);

export const PlanContainer = ({ children, plans: currentPlans, additionalSendingTechnologies }: PlanContainerProps) => {
    const [periodType, setPeriodType] = useState<BillingPeriodEnum>(BillingPeriodEnum.Month);
    const [plans, setPlans] = useImmer<Plan[]>(currentPlans);

    const changePeriodType = useCallback(
        (type: BillingPeriodEnum) => {
            console.log(currentPlans);
            setPlans(currentPlans);
            setPeriodType(type);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentPlans]
    );

    const changeSendingTechnologyOption = useCallback(
        (planId: number, additionalSendingTechnology?: PlanSendingTechnology) => {
            setPlans((draft) => {
                const plan = draft.find((item) => item.id === planId);

                if (!plan.additionalSendingTechnology) {
                    plan.additionalSendingTechnology = [];
                }

                const sendingTechnology = plan.additionalSendingTechnology.find(
                    (item) => item.sendingTechnology === additionalSendingTechnology.sendingTechnology
                );

                if (sendingTechnology) {
                    plan.additionalSendingTechnology = plan.additionalSendingTechnology.filter(
                        (item) => item.sendingTechnology !== additionalSendingTechnology.sendingTechnology
                    );
                }

                if (additionalSendingTechnology.creditAmount) {
                    plan.additionalSendingTechnology.push(additionalSendingTechnology);
                }

                const { installmentAmount, numberOfInstallments, savings, totalWithDiscount } =
                    CalculateSubscriptionPlanDiscounts(plan);

                plan.savings = savings;
                plan.valueWithDiscount = totalWithDiscount;
                plan.installmentAmount = installmentAmount;
                plan.numberOfInstallments = numberOfInstallments;
            });
        },
        [setPlans]
    );

    return (
        <PlanContext.Provider
            value={{
                periodType,
                plans,
                additionalSendingTechnologies,
                changePeriodType,
                changeSendingTechnologyOption
            }}>
            <PlanStyled>{children}</PlanStyled>
        </PlanContext.Provider>
    );
};

export const usePlan = () => useContext(PlanContext);
