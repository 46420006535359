import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { mobileAndTabletOnly, mobileOnly } from '~/styles';

export const PlanStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    margin-bottom: 12rem;
`;

export const ContractPeriodStyled = styled.div`
    margin-top: 1.6rem;
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    div {
        display: flex;
        justify-content: inherit;
        align-items: inherit;
        gap: 4.8rem;
        width: 100%;

        button {
            height: 8rem;
            padding: 1.6rem 5.6rem 1.4rem;
            border-radius: 5rem;
            border: none;
            font-size: ${({ theme }) => theme.fontSizes['2xl']};
            box-shadow: 0 1px 6px 0 ${({ theme }) => rgba(theme.colors.lightBlack, theme.default.boxShadow.alphaValue)};
            flex-direction: column;
            gap: 0.6rem;
            letter-spacing: 1px;

            &[data-active='true'],
            &:hover {
                box-shadow: 1px 6px 16px 0
                    ${({ theme }) => rgba(theme.colors.lightBlack, theme.default.boxShadow.alphaValue)};
            }

            span {
                text-transform: uppercase;
            }
        }
    }
`;

export const PlansContainerStyled = styled.div`
    width: 100%;
    display: flex;
    gap: 3.2rem;

    ${mobileAndTabletOnly(css`
        flex-wrap: wrap;
        justify-content: center;
    `)}
`;

export const PlanCardStyled = styled.div`
    text-align: center;
    padding: 4.8rem 3.2rem 3.2rem;
    box-shadow: 0 1px 6px 0 ${({ theme }) => rgba(theme.colors.lightBlack, theme.default.boxShadow.alphaValue)};
    border-radius: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    position: relative;

    ${mobileOnly(css`
        width: 100%;
    `)}
`;

export const BadgeStyled = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.purple};
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 0.4rem 0.8rem 0.1rem;

    strong {
        text-transform: uppercase;
    }
`;

export const PlanCardHeaderStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    div {
        height: 6.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const PlanCardPriceStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin: 0 auto;

    .oldValue {
        text-decoration: line-through;
    }

    .savings {
        padding: 0.6rem 1.6rem 0.2rem;
        background: ${({ theme }) => theme.colors.deepCerise};
        border-radius: 100px;
        max-width: 32rem;
        margin: 0 auto;
    }

    .value {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
`;

export const SendingTechnologyContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    & > div {
        display: flex;
        flex-direction: column;
        margin-top: 0.4rem;
        border-radius: ${({ theme }) => theme.radii.md};
        background: ${({ theme }) => theme.colors.seaSalt};
        padding: 1.2rem 3.6rem;

        & > div {
            display: flex;
            gap: 1.2rem;
            margin: 0 auto;

            div {
                display: flex;
                align-items: center;
                gap: 0.2rem;
                color: ${({ theme }) => theme.colors.deepCerise};

                p {
                    margin-top: 0.8rem;
                }
            }
        }
    }
`;

export const CustomSendingTechnologyContentStyled = styled.div`
    h3 {
        margin-bottom: 1.2rem;
    }

    & > div {
        width: 100%;
        display: grid;
        grid-gap: 0.8rem;
        grid-template-columns: repeat(2, 1fr);

        & > div {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            width: 100%;

            svg {
                color: ${({ theme }) => theme.subToolColors.emails};
            }

            &:first-child svg {
                color: ${({ theme }) => theme.subToolColors.cartRecovery};
            }

            p {
                margin-top: 0.8rem;
            }
        }

        .select-trigger__label span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 10rem;
        }
    }

    & > button {
        margin: 1.6rem 0;
        width: 100%;
    }

    & > ul {
        list-style: '*';
        margin-left: 8px;
        text-align: start;
    }
`;

export const PlanDetailContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
`;

export const PlanDetailTableHeaderStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.8rem;
    padding: 1.6rem 2.4rem 1.2rem;
    border-radius: 1.6rem;
    background: ${({ theme }) => theme.colors.licorice};
    color: ${({ theme }) => theme.colors.white};

    & > strong {
        margin: auto 0;
        font-size: 2.4rem;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        text-align: center;

        strong {
            font-size: 2.4rem;
        }
    }
`;

export const PlanDetailTableStyled = styled.div`
    box-shadow: 0 1px 6px 0 ${({ theme }) => rgba(theme.colors.lightBlack, theme.default.boxShadow.alphaValue)};
    border-radius: 1.6rem;

    & > div {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.8rem;
        padding: 1.35rem 2.4rem;

        &:not(:first-child) {
            border-top: 1px solid ${({ theme }) => theme.colors.seaSalt};
        }

        &:first-child {
            background-color: ${({ theme }) => theme.colors.seaSalt};

            strong {
                grid-column: 1 / 4;
            }
        }

        p {
            align-self: center;
        }

        & > span {
            display: flex;
            margin: 0 auto;
            color: ${({ theme }) => theme.colors.deepCerise};
            font-weight: ${({ theme }) => theme.fontWeights.medium};

            svg {
                width: 3rem;
                color: ${({ theme }) => theme.colors.licorice};
                margin: 0 auto;

                &.check {
                    width: 1.8rem;
                    color: ${({ theme }) => theme.colors.deepCerise};
                }
            }
        }
    }
`;

export const CustomizePlanContainerStyled = styled.div`
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 5.6rem;
`;

export const CustomizePlanQuestionStyled = styled.div`
    display: inherit;
    flex-direction: inherit;
    align-items: center;

    h1,
    p {
        text-align: center;
    }

    p {
        margin-top: 0.8rem;
    }

    div {
        margin-top: 2.4rem;
        display: inherit;
        gap: 3.2rem;

        & > button {
            padding: 0.4rem 1.2rem;
            margin-top: 0;

            span {
                margin-top: 0.4rem;
            }

            width: 25.2rem;
            padding-top: 0.8rem;
        }
    }
`;

export const StartUpPlanCardStyled = styled.div`
    display: flex;
    gap: 3.2rem;
    padding: 4rem 7.2rem;
    box-shadow: 0 1px 6px 0 ${({ theme }) => rgba(theme.colors.lightBlack, theme.default.boxShadow.alphaValue)};
    border-radius: 1.6rem;

    div {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    a {
        margin-left: auto;
    }
`;

export const FaqContentStyled = styled.div`
    margin-top: 3.2rem;

    & > a {
        margin-top: 1.6rem;
    }

    ul {
        margin-top: 3.2rem;
        border: none;
        gap: 1.6rem;
        display: flex;
        flex-direction: column;
        padding: 0;

        li {
            border: 1px solid ${({ theme }) => theme.colors.lightPurple};
            border-radius: ${({ theme }) => theme.radii.md};

            h6 {
                font-size: ${({ theme }) => theme.fontSizes['2xl']};
            }

            & > div {
                &:first-child {
                    padding: 1.6rem;
                }

                div {
                    padding: 0 1.6rem 1.6rem;
                }
            }
        }
    }
`;

export const FaqButtonStyled = styled.a`
    margin: auto 0;
    display: block;
    width: fit-content;

    button {
        height: fit-content;
        padding: 0.4rem 1.2rem;
        white-space: nowrap;
    }

    span {
        margin-top: 0.4rem;
    }
`;
