import isEmpty from 'lodash/isEmpty';
import { InputFormGroup } from '@components';
import { getDebugValue, RuleDefaultProps, AddRuleDropdown, RuleInputs, LogicalOperatorDropdown, RulesGroup } from '../';
import { RuleStyled } from './styles';

type RuleProps = RuleDefaultProps & {
    isLastRule: boolean;
    showLabels?: boolean;
};

export const Rule = ({
    animation,
    isLastRule,
    path,
    onCreateRule,
    onCreateRuleGroup,
    onRemove,
    rule,
    ruleElementAnimationIndex,
    ruleElementAnimationIndexRef,
    showRemoveButton,
    showLabels = false,
    ruleToDisplayLabels
}: RuleProps) => {
    const hasSubRules = !isEmpty(rule.children);

    return (
        <RuleStyled className='rule-item' initial={{ y: -30 }} animate={{ y: 0, transition: { duration: 0.2 } }}>
            <InputFormGroup inputSize='small' name='id' testId='id' type='text' value={rule.id} isInvisible />
            <InputFormGroup inputSize='small' name='parentId' type='text' value={rule.parentId} isInvisible />

            <RuleInputs
                isVisible={!hasSubRules}
                animation={animation}
                onRemove={onRemove}
                path={path}
                rule={rule}
                ruleElementAnimationIndex={ruleElementAnimationIndex}
                showRemoveButton={showRemoveButton}
                showLabels={showLabels}
            />

            <LogicalOperatorDropdown
                animation={animation}
                debugValue={getDebugValue(rule.id, path)}
                isVisible={hasSubRules}
                onRemove={onRemove}
                rule={rule}
                ruleElementAnimationIndex={ruleElementAnimationIndex}
                showRemoveButton={showRemoveButton}
            />

            <RulesGroup
                animation={animation}
                path={path}
                onCreateRule={onCreateRule}
                onCreateRuleGroup={onCreateRuleGroup}
                onRemove={onRemove}
                rules={rule.children}
                rootRule={rule}
                ruleElementAnimationIndex={ruleElementAnimationIndex}
                ruleElementAnimationIndexRef={ruleElementAnimationIndexRef}
                ruleToDisplayLabels={ruleToDisplayLabels}
            />

            <AddRuleDropdown
                onCreateRule={onCreateRule}
                onCreateRuleGroup={onCreateRuleGroup}
                rule={rule}
                isVisible={isLastRule}
            />
        </RuleStyled>
    );
};
