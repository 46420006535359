import styled from 'styled-components';
import { SubToolColors } from '~/styles/theme';

export const ContainerStyled = styled.div`
    margin-bottom: 10.4rem;
    h1 {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 3.2rem;
    }

    & > div {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const ContentStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const PreCheckoutCardStyled = styled.div<{ expand?: boolean }>`
    flex: 1 1 auto;
    width: 100%;
    max-width: ${({ expand }) => (expand ? '50%' : '25%')};
    padding: 0.8rem;
`;

const DefaultCard = styled.div`
    height: 100%;
    padding: 3.2rem;
    border-radius: 2rem;
    box-shadow: ${({ theme }) => theme.default.boxShadow.secondary};
    display: flex;
    flex-direction: column;
`;

export const CalculatePlanCardStyled = styled(DefaultCard)`
    background-color: ${({ theme }) => theme.colors.white};

    display: flex;
    flex-direction: row;
    gap: 3.2rem;
    justify-content: center;
    align-items: center;

    header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0.8;

        p {
            color: ${({ theme }) => theme.colors.licorice};
            margin: 0;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;

        a,
        button {
            width: 100%;
        }

        a:last-child {
            text-decoration: underline;
        }
    }
`;

export const SubscriptionCardStyled = styled(DefaultCard)`
    background-color: #e9e9e9;
    display: flex;

    a {
        margin-top: auto;
        display: flex;
    }

    h2,
    p {
        color: ${({ theme }) => theme.colors.lightGray};
        margin: 0;
    }

    i {
        width: 6.2rem;
        height: 6.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;

        svg {
            width: 2.538rem;
            height: 2.66rem;
            color: ${({ theme }) => theme.colors.lightGray};
        }
    }
`;

export const SubToolCardStyled = styled(DefaultCard)<{ subToolColor: SubToolColors }>`
    background-color: ${({ theme, subToolColor }) => theme.subToolColors[subToolColor]};
    color: ${({ theme }) => theme.colors.white};
    min-height: 33.25rem;
    i {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;

        svg {
            width: 4.8rem;
            height: 4.8rem;
        }
    }

    header {
        flex: 1;
        h6 {
            color: ${({ theme }) => theme.colors.white};
            margin-bottom: 0.8rem;
        }

        p {
            margin-bottom: 0.8rem;
        }
    }
`;

export const SendingTechnologyContentStyled = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    ul {
        list-style: none;
        display: flex;
        align-items: center;

        li {
            width: 3.2rem;
            height: 3.2rem;
        }
    }

    button {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: underline;
        margin-left: auto;
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        padding: 0;
    }
`;
