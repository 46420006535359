import { FormEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { Button, Tooltip, TooltipTrigger, TooltipContent } from '@components';
import { useTranslatedRoute, useTranslator } from '@hooks';
import { PaymentMethodTypeEnum, SubscriptionStatusEnum } from '@models';
import {
    CheckoutStepsEnum,
    SubscriptionFormData,
    SubscriptionPaymentFormData,
    SubscriptionReducerActionTypesEnum,
    useAddress,
    useCheckout,
    useCoupon,
    usePaymentMethods,
    useTools,
    useBillingData,
    useBillingResponsible,
    useFormOpeners,
    useAlert
} from '@providers';
import { captureException } from '@sentry/nextjs';
import { SubmitHandler } from '@unform/core';
import { scrollTo, isInvisible } from '@utils';
import { routesName } from '~/locales/route';
import { GetSubscriptionDataBySubscription, CreateSubscription } from '~/services/checkoutService';
import { stepUrlParamsTypes } from '~/services/withCheckoutServerSide';
import { CheckoutContent } from '../checkoutContent';
import { PayPalForm } from '../paypalForm';
import { CheckoutFormStyled, ActionButtonsContainerStyled } from './styles';
import { useValidation } from './validation';

export const CheckoutForm = () => {
    const {
        formRef,
        currentStepIndex,
        setCurrentStepIndex,
        subscriptionFormData,
        dispatchSubscriptionFormData,
        subscription,
        hasSubscription,
        hasACardInCanceledSubscription,
        setSubscription,
        isSuspendedPlan,
        setIsSuspendedPlan,
        updateSubscription
    } = useCheckout();
    const { subscriptionPlanSchema, subscriptionDataSchema, subscriptionPaymentSchema } = useValidation();
    const [isLoading, setIsLoading] = useState(false);
    const [paypalNonce, setPaypalNonce] = useState<string | null>(null);
    const { push, query } = useRouter();
    const {
        dialogs: {
            successes: { successfullyCreatedSubscription, successfullyChangedSubscription },
            warnings: { selectAtLeastNumberCredits, subscriptionCancelledForNonPayment },
            errors: { createSubscription }
        },
        checkoutPage: {
            form: { buttons, dataValidation, acceptTerms }
        }
    } = useTranslator();
    const translateRoute = useTranslatedRoute();
    const [erroredFieldName, setErroredFieldName] = useState(null);
    const formOpeners = useFormOpeners();
    const { warning, success, error } = useAlert();

    const stepRoute = {
        0: { href: routesName.checkout.plan, as: translateRoute(routesName.checkout.plan), type: 'plan' },
        1: {
            href: routesName.checkout.subscriptionData,
            as: translateRoute(routesName.checkout.subscriptionData),
            type: 'subscriptionData'
        },
        2: { href: routesName.checkout.payment, as: translateRoute(routesName.checkout.payment), type: 'payment' }
    } as const;

    const { tools } = useTools();
    const { countries, states } = useAddress();
    const { isUpdatingBillingResponsible } = useBillingResponsible();
    const { isUpdatingBillingData } = useBillingData();
    const {
        billingPeriods,
        paymentMethods,
        isUpdatingPaymentMethod,
        setIsUpdatingPaymentMethod,
        oldPaymentMethod,
        setOldPaymentMethod
    } = usePaymentMethods();
    const { couponAttemptResult } = useCoupon();

    useEffect(() => {
        if (erroredFieldName) {
            let erroredField = formRef.current.getFieldRef(erroredFieldName);
            if (isInvisible(erroredField)) {
                /* If user has previously advanced, then came back to a previous step, it is highly likely that
                    formRef.current.getFieldRef will return the reference to the old field (before user advanced).
                    I am not sure if this is an unform bug or intended behaviour (may be intended, since the field has
                    already been "saved" by submitting the form once), but either way, the scroll will only work
                    if you get the most up to date reference to the element, because the old one might not be visible. */
                erroredField = document.getElementsByName(erroredField.getAttribute('name'))[0];
            }

            if (typeof erroredField?.focus === 'function') {
                erroredField?.focus();
                scrollTo(erroredField);
            }

            setErroredFieldName(null);
        }
    }, [erroredFieldName, formRef]);

    useEffect(() => {
        const { step } = query;
        const stepType = stepUrlParamsTypes[String(step)];

        if (stepType !== currentStepIndex) {
            const currentStep =
                stepType === CheckoutStepsEnum.suspendedPlan ? CheckoutStepsEnum.subscriptionPayment : stepType;
            setCurrentStepIndex(currentStep ?? 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query?.step]);

    useEffect(() => {
        if (
            currentStepIndex === CheckoutStepsEnum.subscriptionData &&
            subscriptionFormData?.subscriptionData?.billingData?.address
        ) {
            const currentData: SubscriptionFormData = formRef.current.getData();
            const currentSubscription = currentData?.subscriptionData;

            if (currentSubscription) {
                if (currentSubscription.billingData) {
                    currentData.subscriptionData.billingData.address =
                        subscriptionFormData?.subscriptionData?.billingData?.address;
                }

                formRef.current?.setData(currentData);
            }
        }
    }, [currentStepIndex, formRef, subscriptionFormData]);

    const changeUrl = (newStepNumber: number) => {
        const stepRouteUrl = stepRoute[newStepNumber];
        push({ href: stepRouteUrl.href, query: { step: stepRouteUrl.type } }, stepRouteUrl.as, { shallow: true });
    };

    const handleNext = () => {
        if (currentStepIndex === CheckoutStepsEnum.subscriptionPayment) {
            return;
        }

        setCurrentStepIndex((prevStep) => {
            const newStep = ++prevStep;

            changeUrl(newStep);
            return newStep;
        });
    };

    const handlePrev: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();

        if (isUpdatingPaymentMethod) {
            setIsUpdatingPaymentMethod(false);

            if (oldPaymentMethod) {
                dispatchSubscriptionFormData({
                    type: SubscriptionReducerActionTypesEnum.UpdatePayment,
                    payload: { subscriptionPayment: oldPaymentMethod }
                });
                setOldPaymentMethod(null);
            }

            return;
        }

        if (currentStepIndex === CheckoutStepsEnum.subscriptionPlan) {
            return;
        }

        setCurrentStepIndex((prevStep) => {
            const newStepNumber = --prevStep;
            changeUrl(newStepNumber);

            return newStepNumber;
        });
    };

    const validateUpdatePaymentData = (formData: SubscriptionFormData) => {
        const { hasAcceptedTheTerms, discountCoupon } = formData.subscriptionPayment;
        const {
            creditCard = null,
            paymentMethod = null,
            bankSlip = null
        } = subscriptionFormData?.subscriptionPayment ?? {};

        const selectedPaymentMethod = formData?.subscriptionPayment?.paymentMethod ?? paymentMethod;

        let selectedBankSlip = null;
        let isCreditCardEquals = false;
        let hasNewCreditCardValues = false;

        // eslint-disable-next-line eqeqeq
        if (isUpdatingPaymentMethod && formData?.subscriptionPayment?.paymentMethod != paymentMethod) {
            return { ...formData.subscriptionPayment, paymentMethod: selectedPaymentMethod };
        }

        // eslint-disable-next-line eqeqeq
        if (selectedPaymentMethod == PaymentMethodTypeEnum.CreditCard) {
            const currentCreditCard = { ...creditCard, cvv: '' };
            const newCreditCard = formData?.subscriptionPayment?.creditCard;

            isCreditCardEquals = _isEqual(currentCreditCard, newCreditCard);
            hasNewCreditCardValues = Object.values(newCreditCard ?? {})?.filter(Boolean)?.length !== 0;

            if (
                (isCreditCardEquals || !hasNewCreditCardValues) &&
                // eslint-disable-next-line eqeqeq
                (!oldPaymentMethod || oldPaymentMethod?.paymentMethod == PaymentMethodTypeEnum.CreditCard)
            ) {
                if (!creditCard.cvv) {
                    creditCard.cvv = '000';
                }

                return {
                    ...subscriptionFormData?.subscriptionPayment,
                    hasAcceptedTheTerms,
                    discountCoupon
                } as SubscriptionPaymentFormData;
            }
        }
        // eslint-disable-next-line eqeqeq
        else if (selectedPaymentMethod == PaymentMethodTypeEnum.BankSlip) {
            selectedBankSlip = formData?.subscriptionPayment?.bankSlip ?? bankSlip;
        }

        return { ...formData.subscriptionPayment, paymentMethod: selectedPaymentMethod, bankSlip: selectedBankSlip };
    };

    const handleSubmit: SubmitHandler<SubscriptionFormData> = async (formData, { reset }, event: FormEvent) => {
        try {
            event?.preventDefault();
            formRef.current.setErrors({});

            let subscriptionData = null;
            let paymentFormData: SubscriptionPaymentFormData = null;
            let subscriptionDataFormData = formData.subscriptionData;

            if (currentStepIndex === CheckoutStepsEnum.subscriptionData) {
                subscriptionData = GetSubscriptionDataBySubscription(subscription);
                if (!subscriptionDataFormData) {
                    /* formData.subscriptionData may be empty when user is just passing pages, in which case they have
                        subscribed in another moment. If that is the case, `subscriptionData` will not be empty. */
                    subscriptionDataFormData = subscriptionData;
                }

                if (subscriptionDataFormData) {
                    if (isUpdatingBillingData && !isUpdatingBillingResponsible) {
                        /* If updating billing data, it should not be mandatory to update billing responsible, because you
                            might be able to re-use billing responsible returned from `GetSubscriptionDataBySubscription`. */
                        subscriptionDataFormData.billingResponsible = { ...subscriptionData.billingResponsible };
                    }

                    if (isUpdatingBillingResponsible && !isUpdatingBillingData) {
                        // Same reason as above.
                        subscriptionDataFormData.billingData = { ...subscriptionData.billingData };
                    }
                }
            }

            if (currentStepIndex === CheckoutStepsEnum.subscriptionPayment) {
                setIsLoading(true);

                subscriptionDataFormData = formData.subscriptionData ?? GetSubscriptionDataBySubscription(subscription);

                if (!isSuspendedPlan) {
                    subscriptionDataFormData =
                        subscriptionFormData.subscriptionData ?? GetSubscriptionDataBySubscription(subscription);
                }

                if ((hasSubscription || hasACardInCanceledSubscription) && !isSuspendedPlan) {
                    paymentFormData = validateUpdatePaymentData(formData);
                } else {
                    paymentFormData = formData.subscriptionPayment;
                }
            }

            switch (currentStepIndex) {
                case CheckoutStepsEnum.subscriptionPlan:
                    await subscriptionPlanSchema.validate(formData.subscriptionPlan, {
                        abortEarly: false
                    });

                    if (!formData.subscriptionPlan.creditVolume && !formData.subscriptionPlan.emailMarketingAmount) {
                        formRef.current.setFieldError(
                            'subscriptionPlan.creditVolume',
                            String(dataValidation.subscriptionPlan.creditVolume.required)
                        );

                        formRef.current.setFieldError(
                            'subscriptionPlan.emailMarketingAmount',
                            String(dataValidation.subscriptionPlan.emailMarketingAmount.required)
                        );

                        setErroredFieldName('subscriptionPlan.creditVolume');

                        warning(String(selectAtLeastNumberCredits), null, {
                            enableOutsideClosed: true,
                            returnFocusOnClose: false
                        });

                        return;
                    }

                    dispatchSubscriptionFormData({
                        type: SubscriptionReducerActionTypesEnum.UpdatePlan,
                        payload: { subscriptionPlan: formData.subscriptionPlan }
                    });

                    break;

                case CheckoutStepsEnum.subscriptionData:
                    await subscriptionDataSchema.validate(subscriptionDataFormData, {
                        abortEarly: false
                    });

                    dispatchSubscriptionFormData({
                        type: SubscriptionReducerActionTypesEnum.UpdateSubscriptionData,
                        payload: {
                            subscriptionData: subscriptionDataFormData
                        }
                    });

                    break;

                case CheckoutStepsEnum.subscriptionPayment:
                    await subscriptionPaymentSchema.validate(paymentFormData, {
                        abortEarly: false
                    });

                    if (
                        // eslint-disable-next-line eqeqeq
                        paymentFormData.paymentMethod == PaymentMethodTypeEnum.Gateway &&
                        !paymentFormData.payPal?.nonce
                    ) {
                        paymentFormData.payPal.nonce = paypalNonce;
                    }

                    if (
                        // eslint-disable-next-line eqeqeq
                        paymentFormData.paymentMethod == PaymentMethodTypeEnum.Gateway &&
                        !paymentFormData.payPal?.nonce
                    ) {
                        warning('Token de pagamento não encontrado', null);
                        setIsLoading(false);
                        return;
                    }

                    if (
                        !subscriptionDataFormData.billingData.address.neighborhood ||
                        !subscriptionDataFormData.billingResponsible.email ||
                        !subscriptionDataFormData.billingResponsible.telephone
                    ) {
                        await new Promise((resolve) => {
                            warning(String(dataValidation.subscriptionData.billingData.checkData), null, {
                                onClose: () => resolve(true)
                            });
                        });
                        const changeBillingDataButton = document.getElementById('changeBillingData');
                        changeBillingDataButton.click();
                        setIsLoading(false);
                        return;
                    }

                    dispatchSubscriptionFormData({
                        type: SubscriptionReducerActionTypesEnum.UpdatePayment,
                        payload: {
                            subscriptionPayment: paymentFormData
                        }
                    });

                    CreateSubscription({
                        isUpdate: hasSubscription,
                        subscriptionFormData: {
                            ...subscriptionFormData,
                            subscriptionData: subscriptionDataFormData,
                            subscriptionPayment: paymentFormData
                        },
                        subscription,
                        billingPeriods,
                        tools,
                        couponAttemptResult,
                        paymentMethods,
                        states,
                        countries,
                        isSuspendedPlan
                    })
                        .then((response) => {
                            reset();

                            const newSubscription = response.subscription;
                            if (newSubscription.status === SubscriptionStatusEnum.CanceledByNonpayment) {
                                const { title, description } = subscriptionCancelledForNonPayment;
                                warning(String(title), String(description));

                                updateSubscription(newSubscription);
                                window.scrollTo(0, 0);
                                return;
                            }

                            if (!hasSubscription) {
                                success(
                                    String(successfullyCreatedSubscription.title),
                                    String(successfullyCreatedSubscription.description)
                                );
                            } else {
                                success(
                                    String(successfullyChangedSubscription.title),
                                    String(successfullyChangedSubscription.description)
                                );
                            }

                            setSubscription(newSubscription);

                            // eslint-disable-next-line eqeqeq
                            if (newSubscription.paymentMethod.type == PaymentMethodTypeEnum.BankSlip) {
                                const translatedRoutePath = translateRoute(routesName.checkout.bankSlip, {
                                    invoiceId: response?.invoiceId
                                });

                                push(
                                    {
                                        pathname: routesName.checkout.bankSlip,
                                        query: { invoiceId: response?.invoiceId }
                                    },
                                    translatedRoutePath
                                );

                                return;
                            } else {
                                setIsSuspendedPlan(false);
                                setIsUpdatingPaymentMethod(false);
                            }

                            push(routesName.helpAndTutorials, translateRoute(routesName.helpAndTutorials));
                        })
                        .catch((exception) => {
                            error(String(createSubscription.title), String(createSubscription.description));
                            captureException(exception);
                        })
                        .finally(() => setIsLoading(false));

                    break;
            }

            handleNext();
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};
                const currentStepErrors = {};

                exception.inner.forEach((item) => (errorMessages[item.path] = item.message));

                currentStepErrors[CheckoutStepsEnum[currentStepIndex]] = errorMessages;
                formRef.current.setErrors(currentStepErrors);

                const erroredForm = exception.inner[0].path.split('.')[0];
                if (erroredForm in formOpeners) {
                    formOpeners[erroredForm].open();
                } else {
                    logger.log(
                        `Form ${erroredForm} errored, but is not registered on useFormOpeners.  If your form ` +
                            'is not on an un-rendered state, you may safely ignore this message. Otherwise, ' +
                            'add it to useFormOpeners to ensure auto-scroll to error works properly.'
                    );
                }

                const firstErroredFieldName = Object.keys(formRef.current.getErrors())[0];

                setErroredFieldName(firstErroredFieldName);

                logger.log(errorMessages);
            } else {
                captureException(exception);
            }

            logger.log(exception);
            setIsLoading(false);
        }
    };

    const handleAuthorizePayment = useCallback(
        (nonce: string) => {
            formRef.current?.setFieldValue('subscriptionPayment.payPal.nonce', nonce);
            setPaypalNonce(nonce);
            formRef.current?.submitForm();
        },
        [formRef]
    );

    const currentPaymentMethod = subscriptionFormData?.subscriptionPayment?.paymentMethod;
    const hasAcceptedTheTerms = subscriptionFormData?.subscriptionPayment?.hasAcceptedTheTerms;
    const enablePaymentButton =
        currentStepIndex === CheckoutStepsEnum.subscriptionPayment ||
        currentStepIndex === CheckoutStepsEnum.suspendedPlan;

    const isSubmitButton = currentStepIndex === CheckoutStepsEnum.subscriptionPayment;
    const nextStepIndex =
        currentStepIndex + 1 < CheckoutStepsEnum.subscriptionPayment
            ? currentStepIndex + 1
            : CheckoutStepsEnum.subscriptionPayment;

    return (
        <CheckoutFormStyled
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
                ...subscriptionFormData,
                subscriptionPayment: {
                    ...subscriptionFormData?.subscriptionPayment,
                    creditCard: null
                }
            }}>
            <CheckoutContent step={currentStepIndex} />

            {/* do not use the Next Link because we do not want to update the entire page and queries, just display the link */}
            <ActionButtonsContainerStyled>
                {currentStepIndex !== CheckoutStepsEnum.subscriptionPlan && !isSuspendedPlan && (
                    <a href={stepRoute[currentStepIndex - 1].as} onClick={() => null}>
                        <Button
                            type='button'
                            buttonSize='small'
                            isOutlined
                            color='purple'
                            testId='button__back'
                            onClick={handlePrev}>
                            {String(isUpdatingPaymentMethod ? buttons.cancel : buttons.back)}
                        </Button>
                    </a>
                )}
                <a href={stepRoute[nextStepIndex].as} onClick={() => null}>
                    <Tooltip open={enablePaymentButton && !hasAcceptedTheTerms ? undefined : false}>
                        <TooltipTrigger>
                            <Button
                                type={currentPaymentMethod === PaymentMethodTypeEnum.Gateway ? 'button' : 'submit'}
                                color='purple'
                                buttonSize='small'
                                id='button__submit'
                                disabled={enablePaymentButton && !hasAcceptedTheTerms}
                                inLoading={isLoading}
                                testId={`button__${isSubmitButton ? 'submit' : 'advance'}`}>
                                {isSubmitButton ? String(buttons.submit) : String(buttons.next)}

                                <PayPalForm
                                    onAuthorizePayment={handleAuthorizePayment}
                                    show={
                                        enablePaymentButton &&
                                        hasAcceptedTheTerms &&
                                        !isLoading &&
                                        currentPaymentMethod === PaymentMethodTypeEnum.Gateway
                                    }
                                />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>{String(acceptTerms)}</p>
                        </TooltipContent>
                    </Tooltip>
                </a>
            </ActionButtonsContainerStyled>
        </CheckoutFormStyled>
    );
};

export * from './validation';
