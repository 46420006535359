import { parseISO } from 'date-fns';
import isNumber from 'lodash/isNumber';
import * as yup from 'yup';
import { useTranslator } from '@hooks';
import { PaymentMethodTypeEnum } from '@models';
import {
    CompareDateResult,
    compareDates,
    CPF_LENGTH,
    isAValidCardNumber,
    isAValidCNPJ,
    isAValidCPF,
    MAX_LENGTH_TELEPHONE,
    MIN_LENGTH_TELEPHONE
} from '@utils';
import {
    BillingFormData,
    BillingResponsibleFormData,
    PaymentFormData,
    SubscriptionDataFormData,
    SubscriptionFormData,
    SubscriptionPaymentFormData,
    SubscriptionPlanFormData,
    useCheckout
} from '~/providers/checkoutProvider';

export type PaymentMethodOmit = 'bankSlip' | 'creditCard' | 'payPal';

const PaymentMethodsValues: (string | number)[] = Object.keys(PaymentMethodTypeEnum).filter(
    (key) => typeof PaymentMethodTypeEnum[key] === 'string'
);
const PaymentMethodNumbers = Object.keys(PaymentMethodTypeEnum)
    .filter((key) => typeof PaymentMethodTypeEnum[key] === 'string')
    .map((value) => Number(value));

PaymentMethodsValues.push(...PaymentMethodNumbers);

const MIN_LENGTH_ZIP_CODE = 3;
const MAX_LENGTH_ZIP_CODE = 9;

const LENGTH_CREDIT_CARD_NUMBER = 16;
const LENGTH_CREDIT_CARD_CVV = 3;

export const useValidation = () => {
    const { subscriptionPlan, subscriptionData, subscriptionPayment } =
        useTranslator().checkoutPage.form.dataValidation;
    const { hasSubscription, subscription, hasACardInCanceledSubscription } = useCheckout();

    const subscriptionPlanSchema: yup.SchemaOf<SubscriptionPlanFormData> = yup
        .object()
        .optional()
        .shape({
            creditVolume: yup.number().nullable().notOneOf([undefined], String(subscriptionPlan.creditVolume.required)),
            sendingTechnologies: yup
                .array()
                .of(yup.number())
                .when('creditVolume', {
                    is: (creditVolume: number) => isNumber(creditVolume) && creditVolume > 0,
                    then: (schema) => schema.min(1, String(subscriptionPlan.sendingTechnologies.required))
                }),
            emailMarketingAmount: yup
                .number()
                .nullable()
                .notOneOf([undefined], String(subscriptionPlan.emailMarketingAmount.required)),
            billingPeriodId: yup
                .number()
                .positive()
                .required(String(subscriptionPlan.billingPeriod.required))
                .nullable()
        });

    const billingResponsibleSchema: yup.SchemaOf<BillingResponsibleFormData> = yup
        .object()
        .optional()
        .shape({
            name: yup.string().required(String(subscriptionData.billingResponsible.name.required)),
            email: yup
                .string()
                .email(String(subscriptionData.billingResponsible.email.valid))
                .required(String(subscriptionData.billingResponsible.email.required)),
            telephone: yup
                .string()
                .min(MIN_LENGTH_TELEPHONE, String(subscriptionData.billingResponsible.telephone.minLength))
                .max(MAX_LENGTH_TELEPHONE, String(subscriptionData.billingResponsible.telephone.maxLength))
                .required(String(subscriptionData.billingResponsible.telephone.required)),
            cellPhoneForWhatsApp: yup.string().optional()
        });

    const billingDataSchema: yup.SchemaOf<BillingFormData> = yup.object().shape({
        corporateName: yup.string().required(String(subscriptionData.billingData.corporateName.required)),
        cpfCnpj: yup
            .string()
            .when((value: string, cpfCnpjSchema: yup.StringSchema) =>
                value.length <= CPF_LENGTH
                    ? cpfCnpjSchema.test(
                          'is-a-valid-cpf',
                          String(subscriptionData.billingData.cpfCnpj.cpfInvalid),
                          (value: string) => isAValidCPF(value)
                      )
                    : cpfCnpjSchema.test(
                          'is-a-valid-cnpj',
                          String(subscriptionData.billingData.cpfCnpj.cnpjInvalid),
                          (value: string) => isAValidCNPJ(value)
                      )
            )
            .required(String(subscriptionData.billingData.cpfCnpj.required)),
        telephone: yup.string().when('$validFinancialData', {
            is: true,
            then: (schema) =>
                schema
                    .min(MIN_LENGTH_TELEPHONE, String(subscriptionData.billingResponsible.telephone.minLength))
                    .max(MAX_LENGTH_TELEPHONE, String(subscriptionData.billingResponsible.telephone.maxLength))
                    .required(String(subscriptionData.billingResponsible.telephone.required))
        }),
        email: yup.string().when('$validFinancialData', {
            is: true,
            then: (schema) =>
                schema
                    .email(String(subscriptionData.billingResponsible.email.valid))
                    .required(String(subscriptionData.billingResponsible.email.required))
        }),
        address: yup.object().shape({
            zipCode: yup
                .string()
                .min(MIN_LENGTH_ZIP_CODE, String(subscriptionData.billingData.address.zipCode.minLength))
                .max(MAX_LENGTH_ZIP_CODE, String(subscriptionData.billingData.address.zipCode.maxLength))
                .required(String(subscriptionData.billingData.address.zipCode.required)),
            street: yup.string().required(String(subscriptionData.billingData.address.street.required)),
            number: yup.string().required(String(subscriptionData.billingData.address.number.required)),
            complement: yup.string().optional(),
            neighborhood: yup.string().required(String(subscriptionData.billingData.address.neighborhood.required)),
            city: yup.string().required(String(subscriptionData.billingData.address.city.required)),
            countryCode: yup
                .string()
                .typeError(String(subscriptionData.billingData.address.country.required))
                .required(String(subscriptionData.billingData.address.country.required)),
            stateCode: yup
                .string()
                .typeError(String(subscriptionData.billingData.address.state.required))
                .required(String(subscriptionData.billingData.address.state.required))
        })
    });

    const subscriptionDataSchema: yup.SchemaOf<SubscriptionDataFormData> = yup.object().optional().shape({
        billingResponsible: billingResponsibleSchema,
        billingData: billingDataSchema
    });

    const cvv =
        hasSubscription || hasACardInCanceledSubscription
            ? yup
                  .string()
                  .optional()
                  .test(
                      'length',
                      String(subscriptionPayment.creditCard.cvv.length),
                      (value) => value?.length === LENGTH_CREDIT_CARD_CVV
                  )
            : yup
                  .string()
                  .length(LENGTH_CREDIT_CARD_CVV, String(subscriptionPayment.creditCard.cvv.length))
                  .required(String(subscriptionPayment.creditCard.cvv.required));

    const creditCardNumber =
        hasSubscription || hasACardInCanceledSubscription
            ? yup
                  .string()
                  .optional()
                  .nullable()
                  .test({
                      name: 'isAValidCard',
                      message: String(subscriptionPayment.creditCard.number.isValid),
                      test: (value) =>
                          value === subscription?.card?.number ||
                          (value?.length === LENGTH_CREDIT_CARD_NUMBER && isAValidCardNumber(value))
                  })
            : yup
                  .string()
                  .nullable()
                  .required(String(subscriptionPayment.creditCard.number.required))
                  .length(LENGTH_CREDIT_CARD_NUMBER, String(subscriptionPayment.creditCard.number.length))
                  .test('isAValidCard', String(subscriptionPayment.creditCard.number.isValid), isAValidCardNumber);

    const paymentMethodDataSchema: yup.SchemaOf<Omit<PaymentFormData, PaymentMethodOmit>> = yup
        .object()
        .optional()
        .shape({
            paymentMethod: yup
                .mixed()
                .oneOf(PaymentMethodsValues, String(subscriptionPayment.paymentMethod.required))
                .defined()
                .required(String(subscriptionPayment.paymentMethod.required)),
            creditCard: yup
                .object()
                .optional()
                .when('paymentMethod', {
                    is: (paymentMethod: string) => Number(paymentMethod) === PaymentMethodTypeEnum.CreditCard,
                    then: (schema) =>
                        schema.shape({
                            number: creditCardNumber,
                            cardholderName: yup
                                .string()
                                .nullable()
                                .required(String(subscriptionPayment.creditCard.cardholderName.required)),
                            cvv,
                            expirationMonth: yup
                                .number()
                                .typeError(String(subscriptionPayment.creditCard.expiration.month.required))
                                .required(String(subscriptionPayment.creditCard.expiration.month.required)),
                            expirationYear: yup
                                .number()
                                .typeError(String(subscriptionPayment.creditCard.expiration.year.required))
                                .min(
                                    new Date().getFullYear(),
                                    String(subscriptionPayment.creditCard.expiration.year.minValue)
                                )
                                .required(String(subscriptionPayment.creditCard.expiration.year.required))
                        })
                }),
            bankSlip: yup
                .object()
                .optional()
                .nullable()
                .when('paymentMethod', {
                    is: (paymentMethod: string) => Number(paymentMethod) === PaymentMethodTypeEnum.BankSlip,
                    then: (schema) =>
                        schema.shape({
                            firstDueDate: yup
                                .string()
                                .nullable()
                                .required(String(subscriptionPayment.bankSlip.firstDueDate.required))
                                .test({
                                    name: 'min',
                                    message: subscriptionPayment.bankSlip.firstDueDate.min,
                                    test: (firstDueDateValue) => {
                                        if (!firstDueDateValue) {
                                            return true;
                                        }

                                        const compareResult = compareDates(parseISO(firstDueDateValue), new Date());
                                        return compareResult !== CompareDateResult.Minor;
                                    }
                                })
                        })
                }),
            payPal: yup
                .object()
                .optional()
                .when('paymentMethod', {
                    is: (paymentMethod: string) => Number(paymentMethod) === PaymentMethodTypeEnum.Gateway,
                    then: (schema) =>
                        schema.shape({
                            nonce: yup.string().nullable().optional()
                        })
                })
        });

    const subscriptionPaymentSchema: yup.SchemaOf<Omit<SubscriptionPaymentFormData, PaymentMethodOmit>> = yup
        .object()
        .optional()
        .shape({
            ...paymentMethodDataSchema.fields,
            discountCoupon: yup.string().optional(),
            hasAcceptedTheTerms: yup
                .boolean()
                .required(String(subscriptionPayment.hasAcceptedTheTerms.required))
                .oneOf([true], String(subscriptionPayment.hasAcceptedTheTerms.required))
        });

    const objectSchema: yup.SchemaOf<SubscriptionFormData> = yup.object().shape({
        subscriptionId: yup.number().optional(),
        subscriptionData: subscriptionDataSchema.optional(),
        subscriptionPlan: subscriptionPlanSchema.optional(),
        subscriptionPayment: subscriptionPaymentSchema.optional()
    });

    return {
        objectSchema,
        subscriptionPlanSchema,
        subscriptionDataSchema,
        billingResponsibleSchema,
        billingDataSchema,
        paymentMethodDataSchema,
        subscriptionPaymentSchema
    };
};
