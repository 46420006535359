import { useTranslator } from '@hooks';
import { COMERCIAL_WHATSAPP_CHAT } from '@utils';
import WhatsappIcon from '~/assets/svg/icons/sendingTechnologies/icon__whatsapp.svg';
import { Button, ButtonProps } from '~/components/elements';
import { FaqButtonStyled } from './styles';

export const FaqButton = ({ color = 'green', ...props }: ButtonProps) => {
    const {
        buttons: { contactUs }
    } = useTranslator();

    return (
        <FaqButtonStyled href={COMERCIAL_WHATSAPP_CHAT} target='_blank' rel='noreferrer'>
            <Button color={color} {...props}>
                <WhatsappIcon /> <span>{String(contactUs)}</span>
            </Button>
        </FaqButtonStyled>
    );
};
