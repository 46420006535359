import { useRef, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import _uniqueId from 'lodash/uniqueId';
import { AnimatedArrow, Heading, Text } from '@components';
import { SubToolHelpItemContentStyled, SubToolHelpItemHeaderStyled, SubToolHelpItemStyled } from './styles';

type SubToolHelpItemProps = {
    title: string;
    description: string;
};

export const HelpItem = ({ title, description }: SubToolHelpItemProps) => {
    const [expanded, setExpanded] = useState(false);
    const triggerId = useRef(_uniqueId('help-item-trigger-')).current;
    const helpContentId = useRef(_uniqueId('help-item-region-')).current;

    return (
        <SubToolHelpItemStyled>
            <SubToolHelpItemHeaderStyled
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-controls={helpContentId}>
                <Heading as='h6'>{HTMLReactParser(title)}</Heading>
                <i>
                    <AnimatedArrow direction={expanded ? 'up' : 'down'} />
                </i>
            </SubToolHelpItemHeaderStyled>
            <SubToolHelpItemContentStyled
                isExpanded={expanded}
                role='region'
                aria-labelledby={triggerId}
                id={helpContentId}>
                <Text size='md' lineHeight='tall'>
                    {HTMLReactParser(description)}
                </Text>
            </SubToolHelpItemContentStyled>
        </SubToolHelpItemStyled>
    );
};
