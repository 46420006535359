import { Heading, Button, Text } from '@components';
import { BillingPeriodEnum } from '@models';
import { usePlan } from './container';
import { ContractPeriodStyled } from './styles';

export const ContractPeriodOptions = () => {
    const { periodType, changePeriodType } = usePlan();

    const isYear = periodType === BillingPeriodEnum.Year;
    return (
        <ContractPeriodStyled>
            <Heading as='h1'>Planos</Heading>

            <div>
                <Button
                    data-active={isYear}
                    color={isYear ? 'purple' : 'default'}
                    onClick={() => changePeriodType(BillingPeriodEnum.Year)}>
                    <Text size='2xl' as='strong'>
                        Contrato Anual
                    </Text>
                    <Text size='xxs' as='span' color={isYear ? 'white' : 'deepCerise'}>
                        20% de desconto
                    </Text>
                </Button>
                <Button
                    data-active={!isYear}
                    color={!isYear ? 'purple' : 'default'}
                    onClick={() => changePeriodType(BillingPeriodEnum.Month)}>
                    <Text size='2xl' as='strong'>
                        Contrato Mensal
                    </Text>
                </Button>
            </div>
        </ContractPeriodStyled>
    );
};
