import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import {
    autoUpdate,
    flip,
    offset,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useListNavigation,
    useRole,
    useTransitionStyles,
    useTypeahead
} from '@floating-ui/react';
import { SelectDataType } from '.';

export type UseSelectProps = {
    initialOpen?: boolean;
    defaultOption?: {
        index: number;
        label: string;
    };
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    onOptionChange?: (data: SelectDataType, index: number) => void;
    disableCheck?: boolean;
    blockChange?: boolean;
    contentOffset?: number;
};

export const useSelect = ({
    initialOpen = false,
    open: controlledOpen,
    onOptionChange,
    onOpenChange: setControlledOpen,
    defaultOption = null,
    disableCheck = false,
    blockChange = false,
    contentOffset = 32
}: UseSelectProps) => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const elementsRef = useRef<Array<HTMLElement | null>>([]);
    const labelsRef = useRef<Array<string | null>>([]);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(defaultOption?.index);
    const [selectedLabel, setSelectedLabel] = useState<string | null>(defaultOption?.label);

    useEffect(() => {
        setSelectedIndex(defaultOption?.index ?? null);
        setSelectedLabel(defaultOption?.label ?? null);

        return () => {
            setSelectedIndex(defaultOption?.index ?? null);
            setSelectedLabel(defaultOption?.label ?? null);
        };
    }, [defaultOption]);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const floating = useFloating({
        placement: 'bottom-end',
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [offset({ mainAxis: 8 }), flip()]
    });

    const context = floating.context;

    const handleSelect = useCallback(
        (index: number | null, data?: SelectDataType) => {
            if (!blockChange) {
                setSelectedIndex(index);
            }

            setOpen(false);

            if (index !== null) {
                if (!blockChange) {
                    setSelectedLabel(labelsRef.current[index]);
                }
                onOptionChange && onOptionChange(data, index);
            }
        },
        [setOpen, onOptionChange, blockChange]
    );

    const selectValue = useCallback((index: number, label: string) => {
        setSelectedIndex(index);
        setSelectedLabel(label);
    }, []);

    const clearValue = useCallback(() => {
        setSelectedLabel(null);
        setSelectedIndex(null);
    }, []);

    const handleTypeaheadMatch = (index: number | null) => {
        if (open) {
            setActiveIndex(index);
        } else {
            handleSelect(index);
        }
    };

    const listNav = useListNavigation(context, {
        listRef: elementsRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex
    });

    const typeahead = useTypeahead(context, {
        listRef: labelsRef,
        activeIndex,
        selectedIndex,
        onMatch: handleTypeaheadMatch
    });

    const click = useClick(context, {
        enabled: controlledOpen == null
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'listbox' });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        listNav,
        typeahead,
        click,
        dismiss,
        role
    ]);

    const { isMounted, styles } = useTransitionStyles(context, {
        duration: {
            close: 100,
            open: 200
        }
    });

    return useMemo(() => {
        return {
            ...floating,
            activeIndex,
            selectedIndex,
            isMounted,
            styles,
            selectedLabel,
            elementsRef,
            labelsRef,
            disableCheck,
            contentOffset,
            clearValue,
            selectValue,
            getReferenceProps,
            getFloatingProps,
            getItemProps,
            handleSelect
        };
    }, [
        activeIndex,
        selectedIndex,
        isMounted,
        styles,
        floating,
        selectedLabel,
        elementsRef,
        labelsRef,
        disableCheck,
        contentOffset,
        clearValue,
        selectValue,
        handleSelect,
        getReferenceProps,
        getFloatingProps,
        getItemProps
    ]);
};
