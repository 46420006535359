import styled from 'styled-components';
import { DefaultLayoutContentStyled } from '../styles';

export const LayoutStyled = styled.div`
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LayoutContentStyled = styled(DefaultLayoutContentStyled)`
    & > p {
        color: ${({ theme }) => theme.colors.purple};
    }
`;

export const HeaderContentStyled = styled.header`
    width: 100%;

    div {
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .logotipo {
        padding: 2rem ${({ theme }) => theme.container.paddingX};
    }

    .banner {
        padding: 3.2rem ${({ theme }) => theme.container.paddingX};
        background: rgb(147, 55, 178);
        background: linear-gradient(90deg, rgba(147, 55, 178, 1) 0%, rgba(196, 39, 131, 1) 100%);

        p {
            text-align: center;
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
