import { Box, Heading } from '@components';
import { HelpItem } from '@containers';
import { useTranslator } from '@hooks';
import { FaqButton } from './faqButton';
import { faqData } from './planData';
import { FaqContentStyled } from './styles';

export const PlanFaq = () => {
    const {
        pages: { subscriptionPlans }
    } = useTranslator();
    return (
        <FaqContentStyled>
            <Heading as='h1' color='purple'>
                {String(subscriptionPlans.frequentlyAskedQuestions)}
            </Heading>

            <Box as='ul'>
                {faqData.map((faq) => (
                    <HelpItem key={faq.title} title={faq.title} description={faq.description} />
                ))}
            </Box>

            <FaqButton />
        </FaqContentStyled>
    );
};
