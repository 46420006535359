import { Button, Heading, Text, Link } from '@components';
import { useTranslatedRoute, useTranslator } from '@hooks';
import { routesName } from '~/locales/route';
import { FaqButton } from './faqButton';
import { CustomizePlanQuestionStyled, StartUpPlanCardStyled } from './styles';

export const CustomizePlanQuestion = () => {
    const {
        pages: { subscriptionPlans },
        buttons
    } = useTranslator();

    const translateRoute = useTranslatedRoute();
    return (
        <CustomizePlanQuestionStyled>
            <Heading as='h1' color='purple'>
                {String(subscriptionPlans.customizePlanQuestion.title)}
            </Heading>
            <Text size='2xl'> {String(subscriptionPlans.customizePlanQuestion.description)}</Text>

            <div>
                <FaqButton isOutlined />
                <Link href={routesName.checkout.plan} as={translateRoute(routesName.checkout.plan)}>
                    <Button color='purple'>{String(buttons.customizePlan)}</Button>
                </Link>
            </div>
        </CustomizePlanQuestionStyled>
    );
};

export const StartUpPlanCard = () => {
    const {
        pages: { subscriptionPlans }
    } = useTranslator();
    return (
        <StartUpPlanCardStyled>
            <div>
                <Heading as='h1' color='purple'>
                    {String(subscriptionPlans.startUpPlanCard.title)}
                </Heading>
                <Text size='md'>{String(subscriptionPlans.startUpPlanCard.description1)}</Text>
                <Text size='md'>
                    {String(subscriptionPlans.startUpPlanCard.description2)}
                    <br />
                    <strong>{String(subscriptionPlans.startUpPlanCard.contact)}</strong>
                </Text>
            </div>
            <FaqButton />
        </StartUpPlanCardStyled>
    );
};
