import { Plan, PlanSendingTechnology } from '@models';
import { Plans } from '~/components/containers/plans';
import { usePlan } from '~/components/containers/plans/container';

export type PlanTemplateProps = {
    plans: Plan[];
    additionalSendingTechnologies: PlanSendingTechnology[];
};

const PlanContainer = () => {
    const { periodType, plans } = usePlan();

    return (
        <Plans.CardContainer>
            {plans
                .filter((plan) => plan.billingPeriod === periodType)
                .map((plan) => {
                    return <Plans.Card key={`${plan.billingPeriod}-${plan.name}`} plan={plan} />;
                })}
        </Plans.CardContainer>
    );
};

export const PlanTemplate = (props: PlanTemplateProps) => {
    return (
        <Plans.Container {...props}>
            <Plans.ContractPeriodOptions />

            <PlanContainer />

            <Plans.DetailsTableContainer>
                <Plans.DetailsTableHeader />
                <Plans.DetailsTable type='Features' />
                <Plans.DetailsTable type='Customization' />
                <Plans.DetailsTable type='SupportAndAssistance' />
            </Plans.DetailsTableContainer>

            <Plans.CustomizePlanContainer>
                <Plans.CustomizePlanQuestion />
                <Plans.StartUpPlanCard />
            </Plans.CustomizePlanContainer>

            <Plans.Faq />
        </Plans.Container>
    );
};
