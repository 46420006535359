export const routesName = {
    root: '/',
    about: '/about',
    plans: '/plans',
    checkout: {
        base: '/checkout',
        preCheckout: '/pre-checkout',
        plan: '/checkout?step=plan',
        subscriptionData: '/checkout?step=subscription-data',
        payment: '/checkout?step=payment',
        bankSlip: '/checkout/bankSlip',
        suspendedPlan: '/checkout?step=suspended-plan'
    },
    mySubscription: {
        base: '/mySubscription',
        contractLooseBalance: '/mySubscription/contractLooseBalance'
    },
    helpAndTutorials: '/helpAndTutorials',
    tool: {
        automationTool: '/automationTool',
        statistics: '/tool/statistics',
        _statistics: '/tool/_statistics'
    },
    sendingTechnologySettings: {
        base: '/sendingTechnologiesSetting',
        setting: '/sendingTechnologiesSetting/{sendingTechnology}'
    },
    signIn: '/signIn',
    twoFactor: '/signIn/2fa?{params}',
    shortLivedToken: '/signIn/slt?{params}',
    signOut: '/signOut',
    subTool: {
        subTool: '/automationTool/{subTool}',
        baseConfiguration: '/automationTool/configuration',
        configuration: '/automationTool/{subTool}/configuration',
        baseTemplate: '/automationTool/{subToolPagePath}/template',
        template: '/automationTool/{subToolPagePath}/template?{params}',
        editTemplate: '/automationTool/{subToolPagePath}/template?{params}',
        repurchase: {
            itemConfiguration: '/automationTool/repurchases/itemsConfiguration?{params}'
        },
        customTrigger: {
            configureTrigger: '/automationTool/customTrigger/configureTrigger',
            baseApiTrigger: '/automationTool/customTrigger/apiTrigger',
            apiTrigger: '/automationTool/customTrigger/apiTrigger?{params}'
        },
        manualContactTemplate: '/automationTool/{subTool}/manualContactTemplate',
        editManualContactTemplate: '/automationTool/{subTool}/manualContactTemplate?{params}'
    },
    zendesk: 'https://faq.enviou.com.br/hc/1222904442',
    // TODO: Fix these routes once we have pages for them.
    plugins: '#',
    integrations: '/integracao/exibir/{platformId}',
    partners: '/parceiros',
    myData: '/minha-conta',
    storeDetails: '/loja'
} as const;

export const routes = {
    [routesName.signIn]: {
        'pt-BR': '/acesso',
        'en-US': '/sign-in',
        'es-ES': '/iniciar-sesion'
    },
    [routesName.signOut]: {
        'pt-BR': '/sair',
        'en-US': '/sign-out',
        'es-ES': '/cerrar-sesion'
    },
    [routesName.twoFactor]: {
        'pt-BR': '/acesso/2fa?{params}',
        'en-US': '/sign-in/2fa?{params}',
        'es-ES': '/iniciar-sesion/2fa?{params}'
    },
    [routesName.shortLivedToken]: {
        'pt-BR': '/acesso/slt?{params}',
        'en-US': '/sign-in/slt?{params}',
        'es-ES': '/iniciar-sesion/slt?{params}'
    },
    [routesName.about]: {
        'pt-BR': '/sobre',
        'en-US': '/about',
        'es-ES': '/sobre'
    },
    [routesName.checkout.plan]: {
        'pt-BR': '/checkout?step=plano',
        'en-US': '/checkout?step=plan',
        'es-ES': '/checkout?step=plan'
    },
    [routesName.checkout.subscriptionData]: {
        'pt-BR': '/checkout?step=dados-assinatura',
        'en-US': '/checkout?step=subscription-data',
        'es-ES': '/checkout?step=datos-suscription'
    },
    [routesName.checkout.payment]: {
        'pt-BR': '/checkout?step=pagamento',
        'en-US': '/checkout?step=payment',
        'es-ES': '/checkout?step=pago'
    },
    [routesName.checkout.preCheckout]: {
        'pt-BR': '/pre-checkout',
        'en-US': '/pre-checkout',
        'es-ES': '/pre-checkout'
    },
    [routesName.plans]: {
        'pt-BR': '/planos',
        'en-US': '/planes',
        'es-ES': '/plans'
    },
    [routesName.checkout.suspendedPlan]: {
        'pt-BR': '/checkout?step=plano-suspendido',
        'en-US': '/checkout?step=suspended-plan',
        'es-ES': '/checkout?step=plan-suspendido'
    },
    [routesName.checkout.bankSlip]: {
        'pt-BR': '/checkout/boleto/{invoiceId}',
        'en-US': '/checkout/bank-slip/{invoiceId}',
        'es-ES': '/checkout/factura/{invoiceId}'
    },
    [routesName.mySubscription.base]: {
        'pt-BR': '/minha-assinatura',
        'en-US': '/my-subscription',
        'es-ES': '/mi-suscripcion'
    },
    [routesName.mySubscription.contractLooseBalance]: {
        'pt-BR': '/minha-assinatura/{tool}/contratar-saldo-avulso',
        'en-US': '/my-subscription/{tool}/contract-loose-balance',
        'es-ES': '/mi-suscripcion/{tool}/contratar-saldo-suelto'
    },
    [routesName.tool.automationTool]: {
        'pt-BR': '/minhas-automacoes',
        'en-US': '/my-automations',
        'es-ES': '/mis-automatizaciones'
    },
    [routesName.helpAndTutorials]: {
        'pt-BR': '/ajuda',
        'en-US': '/help',
        'es-ES': '/ayuda'
    },
    [routesName.subTool.subTool]: {
        'pt-BR': '/minhas-automacoes/{subTool}',
        'en-US': '/my-automations/{subTool}',
        'es-ES': '/mis-automatizaciones/{subTool}'
    },
    [routesName.subTool.configuration]: {
        'pt-BR': '/minhas-automacoes/{subTool}/configuracao',
        'en-US': '/my-automations/{subTool}/configuration',
        'es-ES': '/mis-automatizaciones/{subTool}/configuracion'
    },
    [routesName.subTool.template]: {
        'pt-BR': '/minhas-automacoes/{subTool}/modelo',
        'en-US': '/my-automations/{subTool}/template',
        'es-ES': '/mis-automatizaciones/{subTool}/plantilla'
    },
    [routesName.subTool.editTemplate]: {
        'pt-BR': '/minhas-automacoes/{subTool}/modelo?{params}',
        'en-US': '/my-automations/{subTool}/template?{params}',
        'es-ES': '/mis-automatizaciones/{subTool}/plantilla?{params}'
    },
    [routesName.subTool.manualContactTemplate]: {
        'pt-BR': '/minhas-automacoes/{subTool}/modelo-contato-manual',
        'en-US': '/my-automations/{subTool}/manual-contact-template',
        'es-ES': '/mis-automatizaciones/{subTool}/plantilla-contacto-manual'
    },
    [routesName.subTool.editManualContactTemplate]: {
        'pt-BR': '/minhas-automacoes/{subTool}/modelo-contato-manual?{params}',
        'en-US': '/my-automations/{subTool}/manual-contact-template?{params}',
        'es-ES': '/mis-automatizaciones/{subTool}/plantilla-contacto-manual?{params}'
    },
    [routesName.subTool.repurchase.itemConfiguration]: {
        'pt-BR': '/minhas-automacoes/recompras/configuracao-itens?{params}',
        'en-US': '/my-automations/repurchases/items-configuration?{params}',
        'es-ES': '/mis-automatizaciones/recompras/configuracion-articulos?{params}'
    },
    [routesName.subTool.customTrigger.configureTrigger]: {
        'pt-BR': '/minhas-automacoes/gatilho-personalizado/configurar-gatilho',
        'en-US': '/my-automations/custom-trigger/configure-trigger',
        'es-ES': '/mis-automatizaciones/gatillo-personalizado/configurar-gatillo'
    },
    [routesName.subTool.customTrigger.apiTrigger]: {
        'pt-BR': '/minhas-automacoes/gatilho-personalizado/api-gatilho?{params}',
        'en-US': '/my-automations/custom-trigger/api-trigger?{params}',
        'es-ES': '/mis-automatizaciones/gatillo-personalizado/api-gatillo?{params}'
    },
    [routesName.tool.statistics]: {
        'pt-BR': '/ferramenta/estatisticas',
        'en-US': '/tool/statistics',
        'es-ES': '/herramienta/estadisticas'
    },
    [routesName.sendingTechnologySettings.base]: {
        'pt-BR': '/configuracao-canais-envio',
        'en-US': '/sending-channels-setting',
        'es-ES': '/configuracion-canales-envio'
    },
    [routesName.sendingTechnologySettings.setting]: {
        'pt-BR': '/configuracao-canais-envio/{sendingTechnology}',
        'en-US': '/sending-channels-setting/{sendingTechnology}',
        'es-ES': '/configuracion-canales-envio/{sendingTechnology}'
    }
} as const;

export const overwriteRouteParts = {
    'pt-BR': {
        '/': 'Início',
        '/minhas-automacoes': 'Minhas Automações',
        '/configuracao': 'Configuração',
        '/configuracao-itens': 'Configuração de Itens',
        '/gatilho-personalizado': 'Gatilho Personalizado',
        '/recuperacao-carrinho': 'Recuperação de Carrinhos',
        '/lembrete-boletos': 'Cobrança de Boletos',
        '/abandono-navegacao': 'Abandono de Navegação',
        '/email': 'E-mail',
        '/ajuda': 'Ajuda e Tutoriais',
        '/configuracao-canais-envio': 'Configuração de Canais de Envio',
        '/minha-assinatura': 'Minha Assinatura',
        '/contratar-saldo-avulso': 'Contratação de Saldo Avulso',
        '/automacao-marketing': 'Automação de Marketing',
        '/email-marketing': 'E-mail Marketing',
        '/api-gatilho': 'Configurar API',
        '/configurar-gatilho': 'Configurar Gatilho',
        '/estatisticas': 'Estatísticas de Automação'
    },
    'en-US': {
        '/': 'Home',
        '/my-automations': 'My Automations',
        '/items-configuration': 'Items Configuration',
        '/bank-slip': 'Bank Slip',
        '/custom-trigger': 'Custom Trigger',
        '/cart-recovery': 'Cart Recovery',
        '/bank-slip-reminder': 'Bank Slip Reminder',
        '/help': 'Help and Tutorials',
        '/sending-channels-setting': 'Sending Channels Setting',
        '/my-subscription': 'My Subscription',
        '/contract-loose-balance': 'Contracting of Losing Balance',
        '/email-marketing': 'Email Marketing',
        '/api-trigger': 'Configurar API',
        '/configure-trigger': 'Configure Trigger',
        '/statistics': 'Automation Statistics'
    },
    'es-ES': {
        '/': 'Inicio',
        '/mis-automatizaciones': 'Mis Automatizaciones',
        '/configuration': 'Configuración',
        '/estadisticas': 'Estadísticas de Automatización',
        '/configuracion-articulos': 'Configuración de Articulos',
        '/gatillo-personalizado': 'Gatillo Personalizado',
        '/recuperacion-carrito': 'Recuperación de Carritos',
        '/recordatorio-factura': 'Recordario de Factura',
        '/abandono-navegacion': 'Abandono de Navegación',
        '/correo-electronico': 'Correo Electrónico',
        '/ayuda': 'Ayuda y Tutoriales',
        '/configuracion-canales-envio': 'Configuración de Canales de Envío',
        '/mi-suscripcion': 'Mi Suscripción',
        '/contratar-saldo-suelto': 'Contratación de Saldo Suelto',
        '/automatizacion-marketing': 'Automatización de Marketing',
        '/email-marketing': 'E-mail Marketing',
        '/api-gatillo': 'Configurar API',
        '/configurar-gatillo': 'Configurar Gatillo'
    }
} as const;

export const overwriteRouteRoutes = [
    {
        redirectTo: routesName.tool.automationTool,
        route: '/automationTool',
        params: '/:subTool'
    },
    {
        redirectTo: routesName.root,
        route: routesName.sendingTechnologySettings.base,
        params: ''
    },
    {
        redirectTo: routesName.mySubscription.base,
        route: '/mySubscription',
        params: '/:tool'
    }
];

export const subToolParamsTranslator = {
    'pt-BR': {
        'recuperacao-carrinho': 1,
        'lembrete-boletos': 2,
        recompras: 3,
        'gatilho-personalizado': 4,
        'abandono-navegacao': 5,
        email: 6
    },
    'en-US': {
        'cart-recovery': 1,
        'bank-slip-reminder': 2,
        repurchases: 3,
        'custom-trigger': 4,
        retargeting: 5,
        email: 6
    },
    'es-ES': {
        'recuperacion-carrito': 1,
        'recordatorio-factura': 2,
        recompras: 3,
        'gatillo-personalizado': 4,
        'abandono-navegacion': 5,
        'correo-electronico': 6
    }
};

export const toolParamsTranslator = {
    'pt-BR': {
        'automacao-marketing': 1,
        'email-marketing': 2
    },
    'en-US': {
        'automation-tool': 1,
        'email-marketing': 2
    },
    'es-ES': {
        'automatizacion-marketing': 1,
        'email-marketing': 2
    }
};

// Reference link: https://developers.google.com/recaptcha/docs/language
export const googleRecaptchaLocale = {
    'pt-BR': 'pt-BR',
    'en-US': 'en',
    'es-ES': 'es-419'
};
