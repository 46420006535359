import { PlanType } from '@models';

export type TableType = 'Features' | 'Customization' | 'SupportAndAssistance';

export type PlanTable = {
    type: TableType;
    description: string;
    isHeader?: boolean;
} & {
    [key in PlanType]?: boolean | string | number;
};

export type FaqData = {
    title: string;
    description: string;
};

export const planDetails: PlanTable[] = [
    {
        type: 'Features',
        description: 'Funcionalidades',
        isHeader: true
    },
    {
        type: 'Features',
        description: 'Disparador de E-mail Marketing',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação Carrinho Abandonado',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação Abandono de Navegação',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação Recompras',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação Cobrança de Boletos',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação E-mail de Aniversariantes',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação E-mail Cadastro de Newsletter',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Modal Onsite',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Automação Gatilhos Personalizados',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Gatilho de Status de Pedido',
        GrowthAccelerator: false,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Gatilho E-mail de Pós-Compra',
        GrowthAccelerator: false,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Integração com ERP',
        GrowthAccelerator: false,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Features',
        description: 'Integração com CRM',
        GrowthAccelerator: false,
        StrategicExpansion: true,
        Master: true
    },

    {
        type: 'Customization',
        description: 'Personalização',
        isHeader: true
    },
    {
        type: 'Customization',
        description: 'Tratamento de Listas de Envio',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Customization',
        description: 'Produção de Peças para Automações',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Customization',
        description: 'Troca de Banners',
        GrowthAccelerator: false,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'Customization',
        description: 'Produção de Temas Personalizados',
        GrowthAccelerator: 1,
        StrategicExpansion: 3,
        Master: 5
    },

    {
        type: 'SupportAndAssistance',
        description: 'Atendimento e Suporte',
        isHeader: true
    },
    {
        type: 'SupportAndAssistance',
        description: 'Suporte via Chat',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'SupportAndAssistance',
        description: 'Suporte via E-mail',
        GrowthAccelerator: true,
        StrategicExpansion: true,
        Master: true
    },
    {
        type: 'SupportAndAssistance',
        description: 'Suporte via WhatsApp',
        GrowthAccelerator: false,
        StrategicExpansion: false,
        Master: true
    },
    {
        type: 'SupportAndAssistance',
        description: 'Atendimento Dedicado',
        GrowthAccelerator: false,
        StrategicExpansion: false,
        Master: true
    },
    {
        type: 'SupportAndAssistance',
        description: 'Treinamento Individualizado',
        GrowthAccelerator: 'Onboarding',
        StrategicExpansion: 'Livre',
        Master: 'Livre'
    }
];

export const faqData: FaqData[] = [
    {
        title: 'Como é disponibilizado o saldo em minha conta?',
        description:
            'O saldo é disponibilizado mensalmente, com validade de 33 dias. Todo mês o novo saldo é liberado na conta.'
    },
    {
        title: 'E se eu precisar de mais créditos?',
        description:
            'Se você consumir todo seu saldo mensal é possivel contratar saldo avulso ou realizar upgrade do plano.'
    },
    {
        title: 'Contratei o plano anual, o saldo é liberado todo de uma vez?',
        description: 'Será créditado de uma vez todo crédito para uso durante os 365 dias'
    },
    {
        title: 'Tenho que assinar um contrato?',
        description:
            'Sim, nosso contrato é de 12 meses, sem multa rescisória, com aviso prévio de 60 dias para cancelamento.'
    },
    {
        title: 'Preciso ter uma plataforma de e-commerce já integrada para realizar a contratação?',
        description:
            'Sim, nossa lista de plataformas integradas <a href="https://faq.enviou.com.br/hc/1222904442/category/9" target="_blank" rel="noreferrer">está aqui</a>. Caso a sua plataforma não esteja na lista, é preciso falar com o time comercial e um valor a parte será cobrado para a customização de uma tag.'
    },
    {
        title: 'Como funciona o pagamento do plano?',
        description: 'Você pode pagar com boleto ou cartão de crédito. Da forma que preferir.'
    }
];
