import { AnimatePresence } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import { NavigationTriggerRule, NavigationTriggerRuleId } from '@models';
import { Scope } from '@unform/core';
import { isBrowser, TreeItem } from '@utils';
import { getDebugValue, RuleDefaultProps } from '../';
import { Rule } from '../rule';
import { LogicalOperatorDropdown } from '../ruleInputs/logicalOperatorDropdown';
import { RulesGroupStyled } from './styles';

global.showRulesValue = false;

type RulesGroupProps = RuleDefaultProps & {
    isRoot?: boolean;
    rootRule: TreeItem<NavigationTriggerRule>;
};

export const RulesGroup = ({
    onCreateRule,
    onCreateRuleGroup,
    onRemove,
    animation,
    isRoot = false,
    path = '1',
    rootRule,
    ruleElementAnimationIndexRef,
    ruleElementAnimationIndex = 0,
    ruleToDisplayLabels = null,
    rules = []
}: RulesGroupProps) => {
    if (isEmpty(rules)) {
        return <></>;
    }

    let allowRemoveButton = false;

    if (rules.length > 1) {
        allowRemoveButton = true;
    }

    return (
        <>
            <LogicalOperatorDropdown
                animation={animation}
                debugValue={getDebugValue(rootRule?.id, path)}
                onRemove={onRemove}
                rule={rootRule}
                ruleElementAnimationIndex={ruleElementAnimationIndex}
                showRemoveButton={false}
                isVisible={isRoot}
            />

            <RulesGroupStyled className='rule-group' custom={ruleElementAnimationIndex} animate={animation}>
                {isBrowser() && (
                    <AnimatePresence initial={false}>
                        {rules.map((rule, arrayIndex) => {
                            ruleElementAnimationIndexRef.current++;
                            const currentIndex = arrayIndex + 1;
                            const showLabels = ruleToDisplayLabels?.id === rule.id;

                            return (
                                <Scope path={`children[${arrayIndex}]`} key={rule.id}>
                                    <Rule
                                        animation={animation}
                                        isLastRule={rules.length === currentIndex}
                                        path={`${path}.${currentIndex}`}
                                        onCreateRule={onCreateRule}
                                        onCreateRuleGroup={onCreateRuleGroup}
                                        onRemove={onRemove}
                                        rule={rule}
                                        ruleElementAnimationIndex={ruleElementAnimationIndexRef.current}
                                        ruleElementAnimationIndexRef={ruleElementAnimationIndexRef}
                                        showRemoveButton={allowRemoveButton}
                                        showLabels={showLabels}
                                        ruleToDisplayLabels={ruleToDisplayLabels}
                                    />
                                </Scope>
                            );
                        })}
                    </AnimatePresence>
                )}
            </RulesGroupStyled>
        </>
    );
};
