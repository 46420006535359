import { ReactNode, useContext, useEffect, useRef } from 'react';
import { BasicSelect, Label, type BasicSelectProps } from '@components';
import { useField, FormContext } from '@unform/core';
import { SelectFormGroupStyled, SelectFormGroupStyledProps } from './styles';

type SelectFormGroupProps = Omit<BasicSelectProps, 'defaultOption'> &
    SelectFormGroupStyledProps & {
        name: string;
        label?: string;
        enableUnform?: boolean;
        testId?: string;
        icon?: ReactNode;
    };

export const SelectFormGroup = ({
    placeholder,
    options,
    defaultValue: initialValue,
    initialOpen,
    onOpenChange,
    onOptionChange,
    open,
    label,
    flexGrid,
    inputSize,
    name,
    testId,
    disabled,
    disableCheck,
    blockChange,
    icon,
    contentOffset = 32,
    enableUnform = true,
    ...props
}: SelectFormGroupProps) => {
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const { unregisterField } = useContext(FormContext);

    const selectRef = useRef(null);

    useEffect(() => {
        if (!options || !enableUnform) {
            return;
        }

        registerField({
            name: fieldName,
            ref: selectRef.current,
            setValue: (ref, value) => {
                const currentIndex = options.findIndex((item) => item.id === value);
                const hasValue = currentIndex > -1;

                const index = hasValue ? currentIndex : null;
                const label = hasValue ? options[index].label : null;

                ref.selectValue(index, label);
            },
            getValue: (ref: HTMLButtonElement) => {
                const selectedindex = ref.value;
                const currentIndex = selectedindex ? Number(selectedindex) : null;

                if (currentIndex < 0) {
                    return null;
                }

                return options[currentIndex]?.id;
            },
            clearValue: (ref) => ref.clear()
        });

        return () => {
            unregisterField(fieldName);
        };
    }, [fieldName, registerField, unregisterField, options, enableUnform]);

    const selectProps = {
        disabled,
        placeholder,
        options,
        open,
        defaultValue: initialValue ?? defaultValue,
        initialOpen,
        disableCheck,
        blockChange,
        icon,
        testId,
        contentOffset,
        onOpenChange,
        onOptionChange
    };

    return (
        <SelectFormGroupStyled flexGrid={flexGrid} inputSize={inputSize} data-validate={error || undefined} {...props}>
            {label && <Label>{label}</Label>}
            <BasicSelect {...selectProps} ref={selectRef} id={fieldName} />
        </SelectFormGroupStyled>
    );
};
