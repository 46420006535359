import { Text } from '@components';
import { useNumberFunctions, useTranslator } from '@hooks';
import { BillingPeriodEnum } from '@models';
import CheckIcon from '~/assets/svg/controls/check.svg';
import XIcon from '~/assets/svg/icons/icon__x.svg';
import { usePlan } from './container';
import { planDetails, TableType } from './planData';
import { PlanDetailTableHeaderStyled, PlanDetailTableStyled } from './styles';

type PlanDetailsTableProps = { type: TableType };

export const PlanDetailsTableHeader = () => {
    const { periodType, plans } = usePlan();
    const { formatCurrency } = useNumberFunctions();
    const {
        pages: { subscriptionPlans }
    } = useTranslator();

    return (
        <PlanDetailTableHeaderStyled>
            <Text size='2xl' as='strong'>
                {String(subscriptionPlans.planDetailsTableHeader.description)}
            </Text>

            {plans
                .filter((plan) => plan.billingPeriod === periodType)
                .map((plan) => {
                    return (
                        <div key={plan.name}>
                            <Text size='2xl' as='strong'>
                                {plan.name}
                            </Text>
                            <Text size='sm' as='span'>
                                {String(subscriptionPlans.per).toLowerCase()}{' '}
                                {formatCurrency(
                                    plan.billingPeriod === BillingPeriodEnum.Year ? plan.valueWithDiscount : plan.value
                                )}
                            </Text>
                        </div>
                    );
                })}
        </PlanDetailTableHeaderStyled>
    );
};

export const PlanDetailsTable = ({ type }: PlanDetailsTableProps) => {
    const getValueByType = (value: unknown) => {
        if (typeof value === 'boolean') {
            return value ? <CheckIcon className='check' /> : <XIcon />;
        }

        return value;
    };

    return (
        <PlanDetailTableStyled>
            {planDetails
                .filter((details) => details.type === type)
                .map((details) => {
                    if (details.isHeader) {
                        return (
                            <div key={details.description}>
                                <Text size='sm' as='strong'>
                                    {details.description}
                                </Text>
                            </div>
                        );
                    }

                    return (
                        <div key={details.description}>
                            <Text size='sm'>{details.description}</Text>
                            <Text as='span' size='sm'>
                                {getValueByType(details.GrowthAccelerator)}
                            </Text>
                            <Text as='span' size='sm'>
                                {getValueByType(details.StrategicExpansion)}
                            </Text>
                            <Text as='span' size='sm'>
                                {getValueByType(details.Master)}
                            </Text>
                        </div>
                    );
                })}
        </PlanDetailTableStyled>
    );
};
