import { ElementType } from 'react';
import styled, { css, StyledComponentPropsWithRef } from 'styled-components';
import { ThemeColor } from '~/styles/theme';
import { FontSizes, LineHeights, FontWeights } from '~/styles/tokens';

type Props = {
    as?: ElementType;
    size?: FontSizes | string;
    lineHeight?: LineHeights | string;
    fontWeight?: FontWeights;
    color?: ThemeColor | string;
};

type TextStyleData = {
    lineHeight: LineHeights;
    fontWeight: FontWeights;
};

type TextStyle = {
    [key in FontSizes]: TextStyleData;
};

export type TextProps = StyledComponentPropsWithRef<typeof Text> & Props;

const defaultTextStyleBySize: TextStyle = {
    xxs: { lineHeight: 'base', fontWeight: 'regular' },
    xs: { lineHeight: 'base', fontWeight: 'regular' },
    sm: { lineHeight: 'base', fontWeight: 'regular' },
    md: { lineHeight: 'base', fontWeight: 'regular' },
    lg: { lineHeight: 'shorter', fontWeight: 'regular' },
    xl: { lineHeight: 'short', fontWeight: 'regular' },
    '2xl': { lineHeight: 'shorter', fontWeight: 'regular' },
    '4xl': { lineHeight: 'shorter', fontWeight: 'regular' },
    '5xl': { lineHeight: 'tall', fontWeight: 'regular' },
    '6xl': { lineHeight: 'short', fontWeight: 'regular' },
    '7xl': { lineHeight: 'short', fontWeight: 'regular' },
    '8xl': { lineHeight: 'short', fontWeight: 'regular' }
};

const getTextStyle = (size: FontSizes | string): TextStyleData => {
    const style = defaultTextStyleBySize[size];

    return style || { lineHeight: 'base', fontWeight: 'regular' };
};

export const Text = styled.p<Props>`
    ${({ theme, lineHeight, fontWeight, as, color, size = 'xs' }) => css`
        color: ${color ? theme.colors[color] : 'inherit'};
        font-family: ${theme.fonts.primary};
        font-size: ${theme.fontSizes[size] ?? size ?? '1.8rem'};
        line-height: ${theme.lineHeights[lineHeight ?? getTextStyle(size).lineHeight] ?? '106.25%'};
        font-weight: ${theme.fontWeights[
            !fontWeight && as === 'strong' ? 'semiBold' : fontWeight ?? getTextStyle(size).fontWeight
        ] ?? '400'};
    `};
`;

Text.displayName = 'Text';
